import { createReducer, on } from "@ngrx/store";

import {TransactionsActions} from './transactions.actions';
import { ListTransactionsParameters, Transaction } from "src/app/models";
import { CategoryObject,
         CreateAWebsite201Response,
         CreateTransaction201Response,
         GetTransaction200Response,
         TagObject,
         WebsiteObject } from "src/app/test-transactions-client";
import { Account, Pagination } from "@ls/common-ts-models";
import { TableFilter } from "@ls/common-ng-components";

// TODO: Change the unknown/any/never types

export interface TransactionState {
    isBusy: boolean;
    error: string;
    transactionsListSearchParams: ListTransactionsParameters;
    transactions: Transaction[];
    transactionsFilters: { [key: string]: TableFilter };
    transactionsUniqueValues: TransactionsUniqueValuesState;
    transactionDetail?: GetTransaction200Response;
    transactionDetailFileLinksRefreshedOn?: Date;
    transactionWebsiteDetail?: WebsiteObject;
    transactionDetailPending: boolean;
    transactionWebsiteDetailPending: boolean;
    reorderScreenshotsPending: boolean;
    lastScreenhsotUrlRefresh?: Date;
    lastAttachmentUrlRefresh?: Date;
    fileUploadState: TransactionFileUploadState;
    downloadTransactionsState: TransactionFileDownloadState;
    allCategories: CategoryObject[];
    allAccounts: Account[];
    pagination: Pagination;
    bulkUploadStatus?: 'Success' | 'Failure';
    bulkUploadPending?: boolean;
    bulkUploadIds: string[];
}

export interface TransactionFileUploadState {
    uploadsPending: number;
}

export interface TransactionFileDownloadState {
    transactionsFile?: Blob;
    downloadPending: boolean;
}

export interface TransactionsUniqueValuesState {
    error?: {
      errorText: any;
      errorType: any;
    };
    columns: {
        id: string[],
        clients: string[],
        websiteUrl: string[],
        category: string[],
        source: string[],
        requestId: string[],
        transactionDate: string[],
        analysisDate: string[],
        transactionDescription: string[],
        ccLast4: string[],
        transactionAmount: string[],
        transactionCurrency: string[],
        transactionUSDAmount: string[],
        transactionStatus: string[],
        paymentWebsite: string[],
        merchantDescriptor: string[],
        analysisNote: string[],
        internalNote: string[],
        monitoringStatus: string[],
        merchantCountry: string[],
        acquirerCountry: string[],
        recordTags: string[],
    };
    tagsMeta?: TagObject[];
    categoriesMeta?: CategoryObject[];
    pending: boolean;
  }

export const initialTransactionDetail: GetTransaction200Response = {
    id: "",
    websiteId: "",
    accountId: "",
    websiteUrl: "",
    source: "Request",
    transactionStatus: "Pending",
    client:"",
    analysisNote: "",
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: ""
}

export const initialUniqueValuesState: TransactionsUniqueValuesState = {
    columns: {
        merchantCountry: Object.values(CreateTransaction201Response.MerchantCountryEnum),
        acquirerCountry: Object.values(CreateTransaction201Response.AcquirerCountryEnum),
        monitoringStatus: Object.values(CreateAWebsite201Response.MonitoringStatusEnum),
        source: Object.values(CreateTransaction201Response.SourceEnum),
        transactionStatus: Object.values(CreateTransaction201Response.TransactionStatusEnum),
        transactionCurrency: Object.values(CreateTransaction201Response.TransactionCurrencyEnum),
        id: [],
        clients: [],
        websiteUrl: [],
        category: [],
        requestId: [],
        transactionDate: [],
        analysisDate: [],
        transactionDescription: [],
        ccLast4: [],
        transactionAmount: [],
        transactionUSDAmount: [],
        paymentWebsite: [],
        merchantDescriptor: [],
        analysisNote: [],
        internalNote: [],
        recordTags: []
    },
    pending: false,
  };

export const initialWebsiteDetail: WebsiteObject = {
    accountId: "",
    categoryId: "",
    client: "",
    websiteUrl: "",
    monitoringStatus: "Weekly"
}

export const initialFileUploadState: TransactionFileUploadState = {
    uploadsPending: 0,
}

export const initialDownloadTransactionsState: TransactionFileDownloadState  = {
    downloadPending: false
}

export const initialState: TransactionState = {
    isBusy: false,
    error: '',
    transactions: [],
    transactionsListSearchParams: {
        page: 1,
        size: 50
    },
    transactionsUniqueValues: initialUniqueValuesState,
    transactionDetail: initialTransactionDetail,
    transactionWebsiteDetail: initialWebsiteDetail,
    transactionDetailPending: true,
    transactionWebsiteDetailPending: true,
    reorderScreenshotsPending: false,
    fileUploadState: initialFileUploadState,
    downloadTransactionsState: initialDownloadTransactionsState,
    allCategories: [],
    pagination: {
        startPosition: 0,
        endPosition: 0,
        pageNumber: 0,
        totalPages: 0,
        totalRecords: 0,
    },
    transactionsFilters: {},
    allAccounts: [],
    bulkUploadIds: [],
};


export const transactionsReducer = createReducer(
    initialState,
    on(TransactionsActions.list, (_state, { searchParams }) => (
        { ..._state,
            isBusy: true,
            error: '',
            transactionsListSearchParams: searchParams,
            transactions: [] 
        })),
    on(TransactionsActions.listSuccess, (_state, { transactions, pagination }) => (
        { ..._state,
            isBusy: false,
            transactions,
            pagination
        })),

    on(TransactionsActions.downloadTableTransactions, (state) => (
        {
            ...state,
            downloadTransactionsState: {
                ...state.downloadTransactionsState,
                downloadPending: true
            }
    })),

    on(TransactionsActions.downloadTableTransactionsSuccess, (state, props) => (
        {
            ...state,
            downloadTransactionsState: {
                ...state.downloadTransactionsState,
                transactionsFile: props.data,
                downloadPending: false
            }
    })),

    on(TransactionsActions.createTransaction, (state) => (
        {
            ...state,
            isBusy: true,
    })),

    on(TransactionsActions.createTransactionSuccess, (state) => (
        {
            ...state,
            isBusy: false,
    })),

    on(TransactionsActions.deleteTransaction, (state) => (
        {
            ...state,
            isBusy: true,
            transactionDetailPending: true,
            transactionWebsiteDetailPending: true,
    })),

    on(TransactionsActions.deleteTransactionSuccess, (state) => (
        {
            ...state,
            isBusy: false,
            transactionDetailPending: false,
            transactionWebsiteDetailPending: false,
    })),

    on(TransactionsActions.getTransaction, (_state) => (
        {
            ..._state,
            transactionDetail: initialTransactionDetail,
            transactionDetailPending: true
        })),
    on(TransactionsActions.getTransactionSuccess, (_state, { transaction }) => (
        {
            ..._state,
            transactionDetail: transaction,
            transactionDetailPending: false,
        })),
    on(TransactionsActions.getTransactionWebsiteSuccess, (_state, { website }) => (
        {
            ..._state,
            transactionWebsiteDetail: website,
            transactionWebsiteDetailPending: false
        })),
    on(TransactionsActions.getAttachmentUploadUrl, (_state, {file}) => (
        {
            ..._state,
            fileUploadState: {
                uploadsPending: _state.fileUploadState.uploadsPending + 1
            }
    })),
    on(TransactionsActions.confirmAttachmentUploadSuccess, (_state, { fileId, fileName, uploadUrl }) => (
        {
            ..._state,
            transactionDetail: {
                ..._state.transactionDetail,
                attachments: [
                    ..._state.transactionDetail?.attachments ?? [],
                    {
                        id: fileId,
                        name: fileName,
                        url: uploadUrl,
                    }
                ]
            } as GetTransaction200Response,
            fileUploadState: {
                uploadsPending: _state.fileUploadState.uploadsPending - 1
            }
    })),
    on(TransactionsActions.uploadFileError, (_state) => (
        {
            ..._state,
            fileUploadState: {
                uploadsPending: _state.fileUploadState.uploadsPending - 1
            }
        }
    )),
    on(TransactionsActions.deleteAttachment, (_state, {transactionId, attachmentId}) => (
        {
            ..._state,
            transactionDetail: {
                ..._state.transactionDetail,
                attachments: _state.transactionDetail?.attachments?.filter((s) => s.id !== attachmentId)
            } as GetTransaction200Response
    })),
    on(TransactionsActions.downloadAllAttachments, (_state, transactionId) => (
        {
            ..._state,
            lastAttachmentUrlRefresh: new Date()
    })),
    on(TransactionsActions.downloadAllAttachmentsSuccess, (_state, response) => {
            const updatedAttachments = _state.transactionDetail!.attachments!.map((x) => {
                return {
                    ...x,
                    url: response.attachments.find((r) => r.id === x.id).signedUrl ?? ""
                }
            });
            return {
                ..._state,
                transactionDetail: {
                    ..._state.transactionDetail,
                    attachments: [...updatedAttachments]      
                } as GetTransaction200Response
            }   
    }),
    on(TransactionsActions.getScreenshotUploadUrl, (_state, {file}) => (
        {
            ..._state,
            fileUploadState: {
                uploadsPending: _state.fileUploadState.uploadsPending + 1
            }
    })),
    on(TransactionsActions.confirmScreenshotUploadSuccess, (_state, { fileId, fileName, uploadUrl, relativePosition }) => (
        {
            ..._state,
            transactionDetail: {
                ..._state.transactionDetail,
                screenshots: [
                    ..._state.transactionDetail?.screenshots ?? [],
                    {
                        id: fileId,
                        name: fileName,
                        url: uploadUrl,
                        order: relativePosition,
                    }
                ]
            } as GetTransaction200Response,
            fileUploadState: {
                uploadsPending: _state.fileUploadState.uploadsPending - 1
            }
        })),
    on(TransactionsActions.reorderScreenshots, (_state, {transactionId, orderedScreenshots}) => {
        return {
            ..._state,
            reorderScreenshotsPending: true,
            transactionDetail: {
                ..._state.transactionDetail,
                screenshots: [...orderedScreenshots.map((os, index) => {
                    return {
                        ...os,
                        order: index
                    }
                  })]
            } as GetTransaction200Response
    }}),
    on(TransactionsActions.reorderScreenshotsSuccess, (_state) => (
        {
            ..._state,
            reorderScreenshotsPending: false,
    })),
    on(TransactionsActions.downloadScreenshotSuccess, (_state, response) => (
        {
            ..._state,
            transactionDetail: {
                ..._state.transactionDetail,
                screenshots: _state.transactionDetail?.screenshots?.map((s) => {
                    if(s.id === response.response.id){
                        return {
                            ...s,
                            url: response.response.url,
                        }
                    }
                    return{
                        ...s
                    }
                })
            } as GetTransaction200Response
    })),
    on(TransactionsActions.downloadAllScreenshots, (_state, transactionId) => (
        {
            ..._state,
            lastScreenhsotUrlRefresh: new Date()
    })),
    on(TransactionsActions.downloadAllScreenshotsSuccess, (_state, response) => {
            const updatedScreenshots = _state.transactionDetail!.screenshots!.map((x) => {
                return {
                    ...x,
                    url: response.screenshots.find((r) => r.id === x.id).signedUrl ?? ""
                }
            });
            return {
                ..._state,
                transactionDetail: {
                    ..._state.transactionDetail,
                    screenshots: [...updatedScreenshots]      
                } as GetTransaction200Response
            }   
    }),
    on(TransactionsActions.deleteScreenshot, (_state, {transactionId, screenshotId}) => (
        {
            ..._state,
            transactionDetail: {
                ..._state.transactionDetail,
                screenshots: _state.transactionDetail?.screenshots?.filter((s) => s.id !== screenshotId)
            } as GetTransaction200Response
    })),
    on(TransactionsActions.getAllCategoriesSuccess, (_state, { categories }) => (
        {
            ..._state,
            transactionsUniqueValues: {
                ..._state.transactionsUniqueValues,
                columns: {
                    ..._state.transactionsUniqueValues.columns,
                    category: categories.map((c) => c.name).sort() as string[]
                },
                categoriesMeta: categories  
            },
            allCategories: categories,
        })),
    on(TransactionsActions.updateTransaction, (_state, {request}) => (
        {
            ..._state,
            transactionDetailPending: true
        })),
    on(TransactionsActions.updateTransactionSuccess, (_state, {transaction}) => (
        {
            ..._state,
            transactionDetail: {...transaction} as GetTransaction200Response,
            transactionDetailPending: false
        })),
    on(TransactionsActions.updateTransactionError, (_state, {error}) => (
        {
            ..._state,
            error: error,
            transactionDetailPending: false
        })),
    on(TransactionsActions.updateWebsite, (_state, {request}) => (
        {
            ..._state,
            transactionWebsiteDetailPending: true
        })),
    on(TransactionsActions.updateWebsiteSuccess, (_state, {website}) => (
        {
            ..._state,
            transactionWebsiteDetail: { ...website},
            transactionWebsiteDetailPending: false
        })),
    on(TransactionsActions.updateWebsiteError, (_state, {error}) => (
        {
            ..._state,
            error: error,
            transactionDetailPending: false
        })),
    on(TransactionsActions.getUniqueColumnValues, (state) => ({
        ...state,
        pending: true,
        })),
    
    on(TransactionsActions.getUniqueColumnValuesSuccess, (state, props) => ({
        ...state,
        transactionsUniqueValues: {
            ...state.transactionsUniqueValues,
            columns: { ...state.transactionsUniqueValues.columns, [props.column]: props.values },
            tagsMeta: props.tags ?? state.transactionsUniqueValues.tagsMeta,
            categoriesMeta: props.categories ?? state.transactionsUniqueValues.categoriesMeta,
            pending: false,
        }
    })),

    on(TransactionsActions.getUniqueColumnValuesError, (state, props) => ({
    ...state,
        transactionsUniqueValues: {
            ...state.transactionsUniqueValues,
            error: {...props},
            pending: false,
        }
    })),

    on(TransactionsActions.bulkUploadRequest, (state) => ({
        ...state,
        bulkUploadIds: [],
        bulkUploadStatus: undefined,
        bulkUploadPending: true,
    })),

    on(TransactionsActions.bulkUploadSuccess, (state, { ids }) => ({
        ...state,
        bulkUploadIds: ids,
        bulkUploadStatus: 'Success' as 'Success' | 'Failure',
        bulkUploadPending: false,
    })),

    on(TransactionsActions.bulkUploadError, (state, {error, ids}) => ({
        ...state,
        error,
        bulkUploadIds: ids,
        bulkUploadStatus: 'Failure' as 'Success' | 'Failure',
        bulkUploadPending: false,
    })),

    on(TransactionsActions.getAllAccountsSuccess, (state, props) => (
        {
            ...state,
            allAccounts: [...props.accounts],
            transactionsUniqueValues: {
                ...state.transactionsUniqueValues,
                columns:{
                    ...state.transactionsUniqueValues.columns,
                    clients: props.accounts.map((x) => x.name)
                }
            }
    })),

    //Errors
    on(TransactionsActions.updateWebsiteError, (state, props) => ({
        ...state,
        error: props.error,
        transactionWebsiteDetailPending: false
    })),

    on(TransactionsActions.updateTransactionError, (state, props) => ({
        ...state,
        error: props.error,
        transactionDetailPending: false
    })),
    on(TransactionsActions.getTransactionError, (_state, { error }) => (
        {
            ..._state,
            transactionDetailPending: false,
            transactionWebsiteDetailPending: false,
            error
        })),
    on(TransactionsActions.listError, (_state, { error }) => ({
        ..._state,
        isBusy: false,
        error 
    })),

    on(TransactionsActions.downloadTableTransactionsError, (state, {error}) => (
        {
            ...state,
            error,
            downloadTransactionsState: {
                ...state.downloadTransactionsState,
                downloadPending: false
            }
    })),

    on(TransactionsActions.noop, (state) => ({
        ...state,
        transactionsUniqueValues: {
            ...state.transactionsUniqueValues,
            pending: false,
        }
    })),

)