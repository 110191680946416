import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable, filter, take, tap } from 'rxjs';
import { TransactionsActions, selectBulkUploadPending, selectBulkUploadStatus } from 'src/app/store';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent {

  constructor(private store: Store<AppState>) {
    this.bulkUploadPending$ = this.store.select(selectBulkUploadPending).pipe(
      tap(pending => {
        if (pending) {
          this.pending = true;
        } else {
          if (this.pending) {
            this.showBulkUploadChange.emit(false);
          }
        }
      })
    );
  }
  
  @Input() showBulkUpload = false;
  public bulkUploadFile?: File;
  public bulkUploadPending$: Observable<boolean | undefined>;
  public pending = false;

  @Output() showBulkUploadChange = new EventEmitter<boolean>()

  onFilesChanged($event: File[]) {
    this.bulkUploadFile = $event[0];
  }

  upload() {
    if (!this.bulkUploadFile) {
      return;
    }

    this.store.dispatch(TransactionsActions.bulkUploadRequest({file: this.bulkUploadFile}));

    this.store.select(selectBulkUploadStatus).pipe(
      filter(status => !!status),
      take(1)
    ).subscribe(status => {
      if (status === 'Success') {
      this.showBulkUploadChange.emit(false);
      }
    })

  }

  cancelUpload() {
    this.showBulkUploadChange.emit(false);
  }
}
