<div class="transaction-list-component">
    <div class="add-transaction-buttons-container">
        <p-button
            label="Export Transactions"
            size="small"
            icon="pi pi-download"
            iconPos="right"
            (onClick)="exportTransactions()"
        ></p-button>
        <p-button
            [text]="true"
            label="Batch Upload"
            size="small"
            (onClick)="onBatchUploadClick()"
        ></p-button>
        <p-button
            size="small"
            [text]="true"
            label="Add Test Transaction"
            (onClick)="onAddTransactionClick()"
        ></p-button>
    </div>
    <ng-container *ngIf="(transactionsState$ | async) as transactionsState">
        <ls-table
            [value]="transactionsState.transactions"
            [columnsConfig]="columnConfig"
            [pagination]="transactionsState.pagination"
            [pageSize]="pageSize"
            [showCallToActionCircle]="false"
            [showSelectionCheckbox]="false"
            [isLoading]="transactionsState.isBusy"
            [filterSetsEnabled]="false"
            [appliedFilters]="appliedFilters"
            [visibleFilters]="visibleFilters"
            (addFilter)="onAddFilter($event)"
            (applyFilter)="onApplyFilter($event)"
            (removeFilter)="onRemoveFilter($event)"
            (clearFilters)="onClearFilters()"
            (sortChangeEvent)="onSortChange($event)"
            (pageChangeEvent)="onPageChange($event)"
            [availableFilters]="availableFilters"
        >
            <ng-template #row let-transaction>
                <td 
                    (click)="column.containsUrl || column.fieldName === 'mylsLink' ? '' : onClickDetailCell(transaction.id)"
                    class="{{column.width}}"
                    *ngFor="let column of columnConfig">

                        <div *ngIf="column.containsUrl || column.fieldName === 'mylsLink'; else nourl">
                            <div *ngIf="column.containsUrl">
                                <a [href]="ensureHttpPrefix(transaction[column.fieldName])" target="_blank">{{transaction[column.fieldName]}}</a> 
                            </div>
                            <ng-container *ngIf="column.fieldName === 'mylsLink'">
                                <div class="myls-link-container">
                                    <a href="{{transaction[column.fieldName]}}" target="_blank">Link</a> 
                                    <p-button icon="pi pi-copy" [rounded]="true" [text]="true" size="small" (onClick)="onCopyLinkClick(transaction[column.fieldName])"></p-button>
                                </div>
                            </ng-container>
                        </div>

                        <ng-template #nourl>
                            <ng-container *ngIf="column.renderAsTags">
                                <div class="tag-container">
                                    <ls-tag *ngFor="let value of transaction[column.fieldName]" [tagInput]="value">
                                    </ls-tag>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!column.renderAsTags">
                                {{transaction[column.fieldName]}}
                            </ng-container>
                        </ng-template> 
                </td>
            </ng-template>
            <ng-template #emptyMessage>
                <div class="no-transactions-message">
                  <h2> No Transactions found!</h2>
                </div>
            </ng-template>
        </ls-table>
    </ng-container>
</div>
<app-bulk-upload *ngIf="showBatchUpload" [(showBulkUpload)]="showBatchUpload"></app-bulk-upload>
<app-bulk-upload-result *ngIf="showBatchUploadResults" [showBatchUploadResults]="showBatchUploadResults" (showBatchUploadResultsChange)="onBatchUploadResultsClick($event)"></app-bulk-upload-result>
