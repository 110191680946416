import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType, concatLatestFrom } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';

import { TransactionsActions, selectTransactionsNode} from '../store';
import { AnalystSearchForTransactions200Response,
         AnalystSearchForTransactionsRequestParams,
         CategoryObject, 
         CreateAWebsite201Response,
         CreateTransaction201Response,
         CreateTransactionRequest,
         CreateTransactionRequestParams,
         DownloadTransactionsRequestParams,
         GetTransaction200Response,
         MetaColumns,
         TagObject,
         TransactionsService,
         UpdateAWebsiteRequest,
         UpdateAWebsiteRequestParams,
         UpdateTransactionRequest,
         UpdateTransactionRequestParams,
         UploadTransactions200Response,
         WebsiteObject } from "../test-transactions-client";
import { Account, S3BucketResponse, Transaction } from "../models";
import { Pagination } from "@ls/common-ts-models";
import { HttpErrorResponse } from "@angular/common/http";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { UploadService } from "../services/upload.service";
import { FilterType, TableFilter } from "@ls/common-ng-components";
import { ActivatedRoute, Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";
@Injectable()
export class TransactionsEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private transactionClient: TransactionsService,
        private uploadService: UploadService,
        private router: Router,
        private route: ActivatedRoute,
        ) {}

    transactionsList$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.list),
            switchMap((action) => {
                const searchFilters: SearchFilters = this.mapFilters((action.searchParams.filters ?? []));
                const requestParams: AnalystSearchForTransactionsRequestParams = {
                    page: action.searchParams.page,
                    size: action.searchParams.size,
                    sortDirection: action.searchParams.sortDirection,
                    sortField: action.searchParams.sortField,
                    ...searchFilters
                }
                return this.transactionClient.analystSearchForTransactions({ ...requestParams}).pipe(
                        map((response: AnalystSearchForTransactions200Response) => {
                            let startPosition = 0;
                            let endPosition = 0;
                            startPosition = (response.pagination.pageSize * (response.pagination.pageNumber-1))+1;
                            if(startPosition + (response.pagination.pageSize-1) <= response.pagination.totalRecords)
                            {
                                endPosition = startPosition - 1 + response.pagination.pageSize;
                            }
                            else{
                                endPosition = startPosition - 1 + (response.pagination.totalRecords % response.pagination.pageSize);
                            }
                            const pagination = {
                                ...response.pagination,
                                pageNumber: response.pagination.pageNumber,
                                startPosition,
                                endPosition
                            }
                            const results = response.results.map((x) => {
                                return {
                                    ...x,
                                    mylsLink: `${environment.MYLS_HOST}/#/services/test-transactions?id=${x.id}&showDetails=${x.id}`
                                };
                            });
                            return TransactionsActions.listSuccess({transactions: results as Transaction[], pagination: {...pagination} as Pagination});
                        })
                    )
                }
            ),
        ),
    );

    public downloadTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActions.downloadTableTransactions),
      switchMap((action) => {
        const filters: SearchFilters = this.mapFilters(action.filters ?? []);

        const requestParams: DownloadTransactionsRequestParams = {
            ...filters
        };
        return this.transactionClient.downloadTransactions(requestParams).pipe(
          map((response) => {
            const fileText = response.replaceAll('\\n', '\n').replaceAll('\\"', '"').slice(1,-1);
            return TransactionsActions.downloadTableTransactionsSuccess({
              data: new Blob([fileText], { type: 'text/csv', endings: 'native' }),
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              TransactionsActions.downloadTableTransactionsError({
                error: error.message,
              }),
            ),
          ),
        );
      }),
    ),
  );
    
    getAllCategories$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getAllCategories),
            switchMap(() =>
                this.transactionClient.listAllCategories({}).pipe(
                    map((response: CategoryObject[]) => TransactionsActions.getAllCategoriesSuccess({ categories: response }))
                )
            ),
        ),
    );
    
    createTransaction$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.createTransaction),
            switchMap((action) => {
                    let dateFormattedRequest: CreateTransactionRequest = {...action.request};
                    if(action.request?.analysisDate){
                        dateFormattedRequest = {
                            ...dateFormattedRequest,
                            analysisDate: formatDate(action.request?.analysisDate, 'yyyy-MM-dd', 'en')
                        }
                    }
                    if(action.request?.transactionDate){
                        dateFormattedRequest = {
                            ...dateFormattedRequest,
                            transactionDate: formatDate(action.request?.transactionDate, 'yyyy-MM-dd', 'en')
                        }
                    }
                    return this.transactionClient.createTransaction({ CreateTransactionRequest: { ...dateFormattedRequest }}).pipe(
                        map((response: CreateTransaction201Response) => TransactionsActions.createTransactionSuccess({ response })),
                        catchError((error) => of(TransactionsActions.createTransactionError({error: error.message})))
                    )
                }
            ),
        ),
    );

    createTransactionSuccess$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.createTransactionSuccess),
            map((action) => {
                this.router.navigate(['transactions', action.response.id ]);
                return TransactionsActions.noop();
            }),
        )
        
    );

    getTransaction$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getTransaction),
            switchMap((action) =>
                this.transactionClient.getTransaction({id: action.id}).pipe(
                    map((response: GetTransaction200Response) => TransactionsActions.getTransactionSuccess({ transaction: response })),
                    catchError((error) => of(TransactionsActions.getTransactionError({error: error.message})))
                )
            ),
        ),
    );

    getWebsiteAfterGetTransactionSuccess$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getTransactionSuccess),
            switchMap((action) =>
                this.transactionClient.getAWebsiteByID({id: action.transaction.websiteId}).pipe(
                    map((response: WebsiteObject) => TransactionsActions.getTransactionWebsiteSuccess({ website: response }))
                )
            )
        ),  
    );

    updateTransaction$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.updateTransaction),
            switchMap((action) =>
                {
                    let dateFormattedRequest: UpdateTransactionRequestParams = {...action.request};
                    if(action.request.UpdateTransactionRequest?.analysisDate){
                        dateFormattedRequest = {
                            ...dateFormattedRequest,
                            UpdateTransactionRequest: {
                                ...dateFormattedRequest.UpdateTransactionRequest,
                                analysisDate: formatDate(action.request.UpdateTransactionRequest.analysisDate, 'yyyy-MM-dd', 'en')
                            }
                        }
                    }
                    if(action.request.UpdateTransactionRequest?.transactionDate){
                        dateFormattedRequest = {
                            ...dateFormattedRequest,
                            UpdateTransactionRequest: {
                                ...dateFormattedRequest.UpdateTransactionRequest,
                                transactionDate: formatDate(action.request.UpdateTransactionRequest.transactionDate, 'yyyy-MM-dd', 'en')
                            }
                        }
                    }
                    const prunedRequest = this.pruneNullsFromTransactionUpdateRequest(dateFormattedRequest);
                    return this.transactionClient.updateTransaction({...prunedRequest}).pipe(
                        map((response: CreateTransaction201Response) => TransactionsActions.updateTransactionSuccess({ transaction: response }))
                    )
                }
            ),
        ),
    );

    deleteTransaction$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.deleteTransaction),
            switchMap((action) =>
                this.transactionClient.deleteTransaction({ id: action.transactionId}).pipe(
                    map(() => TransactionsActions.deleteTransactionSuccess())
                )
            ),
        ),
    );

    goBackToListWhenTransactionDeletes$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.deleteTransactionSuccess),
            map((action) => {
                this.router.navigate(['transactions' ]);
                return TransactionsActions.noop();
            }),
        )
    )

    updateWebsite$ = createEffect(() => this.actions$
    .pipe(
        ofType(TransactionsActions.updateWebsite),
        switchMap((action) => {

            const prunedRequest = this.pruneNullsFromWebsiteUpdateRequest(action.request);
            return this.transactionClient.updateAWebsite({...prunedRequest}).pipe(
                map((response: CreateAWebsite201Response) => TransactionsActions.updateWebsiteSuccess({ website: response })),
                catchError((error: Error) => of(TransactionsActions.updateWebsiteError({error: error.message})))
            )}
            ),
        ),
    );

    getScreenshotUploadUrl$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getScreenshotUploadUrl),
            mergeMap((action) => {
                return this.transactionClient.uploadScreenshot({id: action.transactionId, UploadFileRequest: { name: action.fileName, order: action.relativePosition }})
                .pipe(
                    map((response) => {
                        const bucketResponse = {...response} as unknown as S3BucketResponse;
                        return TransactionsActions.getScreenshotUploadUrlSuccess({url: bucketResponse.url, fileId: bucketResponse.id, file: action.file, fileName: bucketResponse.name, fields: bucketResponse.fields, relativePosition: action.relativePosition});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );  

    uploadScreenshot$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getScreenshotUploadUrlSuccess),
            mergeMap((action) => {
                return this.uploadService.uploadFile(action.file, action.url, action.fields)
                .pipe(
                    map((_response) => {
                        return TransactionsActions.confirmScreenshotUpload({fileId: action.fileId, fileName: action.fileName, uploadUrl: action.url, relativePosition: action.relativePosition});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );

    confirmScreenshot$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.confirmScreenshotUpload),
            concatLatestFrom(() => [
                this.store.select(selectTransactionsNode)
            ]),
            mergeMap(([action, state]) => {
                return this.transactionClient.confirmScreenshotUpload({id: state.transactionDetail?.id as string, screenshotId: action.fileId})
                .pipe(
                    map((_response) => {
                        return TransactionsActions.confirmScreenshotUploadSuccess({ fileId: action.fileId, fileName: action.fileName, uploadUrl: action.uploadUrl, relativePosition: action.relativePosition});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );

    getAttachmentUploadUrl$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getAttachmentUploadUrl),
            mergeMap((action) => {
                return this.transactionClient.uploadAttachment({id: action.transactionId, UploadFileRequest: { name: action.fileName }})
                .pipe(
                    map((response) => {
                        const bucketResponse = {...response} as unknown as S3BucketResponse;
                        return TransactionsActions.getAttachmentUploadUrlSuccess({url: bucketResponse.url, file: action.file, fileId: bucketResponse.id, fileName: bucketResponse.name, fields: bucketResponse.fields});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );

    uploadAttachment$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getAttachmentUploadUrlSuccess),
            mergeMap((action) => {
                return this.uploadService.uploadFile(action.file, action.url, action.fields)
                .pipe(
                    map((_response) => {
                        return TransactionsActions.confirmAttachmentUpload({fileId: action.fileId, fileName: action.fileName, uploadUrl: action.url});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );

    confirmAttachment$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.confirmAttachmentUpload),
            concatLatestFrom(() => [
                this.store.select(selectTransactionsNode)
            ]),
            mergeMap(([action, state]) => {
                return this.transactionClient.confirmAttachmentUpload({id: state.transactionDetail?.id as string, attachmentId: action.fileId})
                .pipe(
                    map((_response) => {
                        return TransactionsActions.confirmAttachmentUploadSuccess({fileId: action.fileId, fileName: action.fileName, uploadUrl: action.uploadUrl});
                    }),
                    catchError((error: Error) => of(TransactionsActions.uploadFileError({error: error.message})))
                )
            }),
        ),
    );

    downloadAllAttachments$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.downloadAllAttachments),
            mergeMap((action) => {
                return this.transactionClient.downloadAllAttachments({ id: action.transactionId })
                .pipe(
                    map((response) => {
                        return TransactionsActions.downloadAllAttachmentsSuccess({attachments: [...response]});
                        })
                    )
            }),
        )   
    );

    reorderScreenshots$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.reorderScreenshots),
            switchMap((action) => {
                return this.transactionClient.reorderScreenshots({ id: action.transactionId, request_body: action.orderedScreenshots.map(x => x.id)})
                .pipe(
                    map((_response) => {
                        return TransactionsActions.reorderScreenshotsSuccess();
                    })
                )
            }),
        )
    );

    downloadScreenshot$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.downloadScreenshot),
            mergeMap((action) => {
                return this.transactionClient.downloadScreenshot({ id: action.transactionId, screenshotId: action.screenshotId})
                .pipe(
                    map((response) => {
                        return TransactionsActions.downloadScreenshotSuccess({response});
                        })
                    )
            }),
        )   
    );

    downloadAllScreenshots$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.downloadAllScreenshots),
            mergeMap((action) => {
                return this.transactionClient.downloadAllScreenshots({ id: action.transactionId })
                .pipe(
                    map((response) => {
                        return TransactionsActions.downloadAllScreenshotsSuccess({screenshots: [...response]});
                        })
                    )
            }),
        )   
    );

    deleteScreenshot$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.deleteScreenshot),
            mergeMap((action) => {
                return this.transactionClient.deleteScreenshot({ id: action.transactionId, screenshotId: action.screenshotId})
                .pipe(
                    map((_response) => {
                        return TransactionsActions.deleteScreenshotSuccess();
                        })
                    )
            }),
        )   
    );

    deleteAttachment$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.deleteAttachment),
            mergeMap((action) => {
                return this.transactionClient.deleteAttachment({ id: action.transactionId, attachmentId: action.attachmentId})
                .pipe(
                    map((_response) => {
                        return TransactionsActions.deleteAttachmentSuccess();
                        })
                    )
            }),
        )   
    );

    public getAllAccounts$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getAllAccounts),
            switchMap((_action) => {
                return this.transactionClient.listAllAccounts({}).pipe(
                    map((response) => {
                        return TransactionsActions.getAllAccountsSuccess({accounts: [...response.accounts as Account[]] })
                    })
                )
            })
        )
    );
    
    public getUnique$ = createEffect(() =>
        this.actions$.pipe(
        ofType(TransactionsActions.getUniqueColumnValues),
        withLatestFrom(this.store.select(selectTransactionsNode).pipe(map((s) => s.transactionsUniqueValues))),
        mergeMap(([action, existingColumns]) => {
            // some values have a different endpoint.
            if (action.column === MetaColumns.tags) {
            return this.transactionClient.listTags({}).pipe(
                map((tags: TagObject[]) =>
                TransactionsActions.getUniqueColumnValuesSuccess({
                    column: action.column,
                    values: tags ? tags.map((t) => t.name ? t.name : '') : [] as string[],
                    tags,
                }),
                ),
                catchError((error: HttpErrorResponse) =>
                of(
                    TransactionsActions.getUniqueColumnValuesError({ errorText: error.message, errorType: error['status'] ?? 0 }),
                ),
                ),
            );
            }
            if (action.column === MetaColumns.merchantCategoryCode) {
            return this.transactionClient.listAllCategories({}).pipe(
                map((cat: CategoryObject[]) =>
                TransactionsActions.getUniqueColumnValuesSuccess({
                    column: action.column,
                    values: cat ? cat.map((c) => c.name) as string[] : [] as string[],
                    categories: cat,
                }),
                ),
                catchError((error: HttpErrorResponse) =>
                of(
                    TransactionsActions.getUniqueColumnValuesError({ errorText: error.message, errorType: error['status'] ?? 0 }),
                ),
                ),
            );
            }
            // if not a 'special' value, get all unique values.
            return this.transactionClient.listUniqueValues({ column: action.column }).pipe(
            map((response) =>
                TransactionsActions.getUniqueColumnValuesSuccess({ column: response.column, values: response.values }),
            ),
            catchError((error: HttpErrorResponse) =>
                of(TransactionsActions.getUniqueColumnValuesError({ errorText: error.message, errorType: error['status'] ?? 0 })),
            ),
            );
        }),
        ),
    );

    public bulkUpload$ = createEffect(() => 
        this.actions$.pipe(
            ofType(TransactionsActions.bulkUploadRequest),
            switchMap(async action => await action.file.text()),
            mergeMap((fileContents) => {
                return this.transactionClient.uploadTransactions({body: fileContents}).pipe(
                    map((response: UploadTransactions200Response) => TransactionsActions.bulkUploadSuccess({ ids: response.ids })),
                    catchError((err: HttpErrorResponse) => {
                        let errMsg: string;
                        if(err?.error?.errors) {
                            errMsg = err.error.errors.map((e: unknown) => JSON.stringify(e)).join(';\r');
                        } else {
                            errMsg = err?.error?.message ?? err?.error ?? err;
                        }
                        return of(TransactionsActions.bulkUploadError({error: errMsg, ids: err.error.ids}));
                    }),
                )
            })
        )
    )
    
    private mapFilters(filters: TableFilter[]): SearchFilters {
        return (filters || [] as TableFilter[]).reduce((searchFilters, filter) => {
          const filterValueString =
            filter.filterType === FilterType.date_range
              ? filter.value!.map((d) => d?.toISOString()).join(',')
              : filter.filterType === FilterType.multi_select
              ? filter.value!.map((v) => v.name).join(',')
              : filter.filterType === FilterType.numeric_range
              ? filter.value!.map((n) => `${n}`).join(',')
              : filter.filterType === FilterType.single_line
              ? filter.value
              : filter.filterType === FilterType.multi_line
              ? filter.value!.replaceAll('\n', ',')
              : '';
              
        searchFilters = {
            ...searchFilters,
            [filter.propertyName as keyof SearchFilters]: filterValueString
        }
          return searchFilters;
        }, {} as SearchFilters);
      }

    private pruneNullsFromTransactionUpdateRequest = (toPrune: UpdateTransactionRequestParams): UpdateTransactionRequestParams => {
        // some fields are valid in the empty string state.
        const analysisNote = toPrune.UpdateTransactionRequest?.analysisNote;
        const internalNote = toPrune.UpdateTransactionRequest?.internalNote;
        const prunedRequest: {[key: string]: any} = {}
        if(toPrune.UpdateTransactionRequest){
            Object.keys(toPrune.UpdateTransactionRequest as UpdateTransactionRequest).forEach((key) => {
                if(toPrune.UpdateTransactionRequest![key as keyof UpdateTransactionRequest] !== null && toPrune.UpdateTransactionRequest![key as keyof UpdateTransactionRequest] !== ""){
                    prunedRequest[key] = toPrune.UpdateTransactionRequest![key as keyof UpdateTransactionRequest] ?? undefined;
                }
            });
        }
        return {
            ...toPrune,
            UpdateTransactionRequest: {...prunedRequest, analysisNote, internalNote}
        } as UpdateTransactionRequestParams;
    }

    private pruneNullsFromWebsiteUpdateRequest = (toPrune: UpdateAWebsiteRequestParams): UpdateAWebsiteRequestParams => {
        const prunedRequest: {[key: string]: any} = {}
        if(toPrune.UpdateAWebsiteRequest){
            Object.keys(toPrune.UpdateAWebsiteRequest as UpdateAWebsiteRequest).forEach((key) => {
                if(toPrune.UpdateAWebsiteRequest![key as keyof UpdateAWebsiteRequest] !== null && toPrune.UpdateAWebsiteRequest![key as keyof UpdateAWebsiteRequest] !== ""){
                    prunedRequest[key] = toPrune.UpdateAWebsiteRequest![key as keyof UpdateAWebsiteRequest] ?? undefined;
                }
            });
        }
        return {
            ...toPrune,
            UpdateAWebsiteRequest: {...prunedRequest}
        } as UpdateAWebsiteRequestParams;
    }
}
interface SearchFilters {
    website?: string;
    category?: string;
    client?: string;
    merchantDescriptor?: string;
    transactionDate?: string;
    amount?: number;
    ccLast4?: number;
    transactionStatus?: string;
    tags?: string;
    createDate?: string;
    source?: string;
    requestId?: string;
    cardAcceptor?: string;
    rightsHolder?: string;
    terminalId?: string;
    transactionId?: string;
  }
