import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import { JwtModule } from '@auth0/angular-jwt';

import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton'
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { MessageService } from 'primeng/api';

import { ApiModule, Configuration, ConfigurationParameters, TransactionsService } from './test-transactions-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {environment} from '../environments/environment';

import { applicationReducer } from './store';
import { AppEffects } from './effects';
import { ComponentsModule } from './components';

import { LegitscriptUiModule } from '@ls/common-ng-components';
import { TransactionCreatePageComponent } from './pages/transaction-create-page/transaction-create-page.component';
import { UploadService } from './services/upload.service';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { LoginComponent } from './pages/login/login.component';
import { TransactionsDetailPageComponent } from './pages/transaction-detail-page/transaction-detail-page.component';
import { TransactionsListPageComponent } from './pages/transactions-list-page/transactions-list-page.component';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';



export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}

localStorage.setItem('useAuth0', 'true');

// Configuration for the Test Transactions API Client
export function apiConfigFactory (): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    accessToken: () => localStorage.getItem('access_token') ?? '',
    basePath: environment.TEST_TRANSACTIONS_HOST,
  }
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingPageComponent,
    TransactionsListPageComponent,
    TransactionCreatePageComponent,
    TransactionsDetailPageComponent,
    LoginComponent,
    AccessDeniedPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      useRefreshTokens: true,
      // useRefreshTokensFallback: true, // Do NOT use this option; 3rd party cookies are blocked!
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: environment.AUTH0_REDIRECT_URI,
        audience: environment.AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access'
      },
      httpInterceptor: {
        allowedList: [
          { uri: `${environment.TEST_TRANSACTIONS_HOST}/*` }
        ],
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
      },
    }),
    HttpClientModule,
    // PrimeNG modules
    ButtonModule,
    CalendarModule,
    ChipsModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    MultiSelectModule,
    SkeletonModule,
    TableModule,
    TabMenuModule,
    // App specific modules
    AppRoutingModule,
    StoreModule.forRoot(applicationReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 25 states
    }),
    EffectsModule.forRoot(...AppEffects),
    // App Modules
    ApiModule.forRoot(apiConfigFactory),
    ComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    LegitscriptUiModule,
  ],
  providers: [
    AuthService,
    UploadService,
    TransactionsService,
    //PrimeNG services
    MessageService,
    // setup the Interceptors to append the access token
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
