import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { selectBulkUploadIds, selectErrorMessages } from 'src/app/store';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bulk-upload-result',
  templateUrl: './bulk-upload-result.component.html',
  styleUrls: ['./bulk-upload-result.component.scss']
})
export class BulkUploadResultComponent implements OnInit {

  constructor(private store: Store<AppState>, private messageService: MessageService) {
  }

  @Input() showBatchUploadResults = false;
  @Output() showBatchUploadResultsChange = new EventEmitter<string[] | undefined>();

  public ids: string[] = [];
  public links: string[] = [];
  public errors: string | undefined;

  ngOnInit(): void {
    
    this.store.select(selectBulkUploadIds)
      .subscribe(ids => {
        this.ids = ids || [];
        this.links = this.ids.map(this.createMyLSLink) || [];
      });
    
    this.store.select(selectErrorMessages).subscribe(errors => this.errors = errors);
  }

  createMyLSLink(id: string) {
    return `${environment.MYLS_HOST}/#/services/test-transactions?id=${id}&showDetails=${id}`;
  }

  copyIds() {
    navigator.clipboard.writeText(this.links.join('\n'));
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Links copied to clipboard' });
  }

  closeClick() {
    this.showBatchUploadResultsChange.emit(this.ids.length > 0 ? this.ids : undefined);
  }
}
