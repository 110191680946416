import { createFeatureSelector, createSelector } from "@ngrx/store";

import {TransactionState} from './transactions.reducer';

export const selectTransactionsNode = createFeatureSelector<TransactionState>('transactions');

export const selectTransactions = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.transactions
)

export const selectTransactionDetail = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.transactionDetail
)

export const selectTransactionDetailScreenshots = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.transactionDetail?.screenshots ?? []
)

export const selectTransactionDetailAttachments = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.transactionDetail?.attachments ?? []
)

export const selectTransactionWebsiteDetail = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.transactionWebsiteDetail
)

export const selectUniqueValuesState = createSelector(
    selectTransactionsNode, (transactions) => transactions.transactionsUniqueValues
)

export const selectBulkUploadStatus = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.bulkUploadStatus
)

export const selectBulkUploadIds = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.bulkUploadIds
)

export const selectBulkUploadPending = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.bulkUploadPending
)

export const selectErrorMessages = createSelector(
    selectTransactionsNode,
    (transactions) => transactions.error
)
