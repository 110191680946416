import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TransactionState, TransactionsActions } from 'src/app/store';
import { selectTransactionDetailAttachments, selectTransactionDetailScreenshots, selectTransactionsNode } from 'src/app/store/transactions/transactions.selectors';
import { Observable, Subject, catchError, of, take, takeUntil } from 'rxjs';
import { CategoryObject, GetTransaction200Response, TransactionDocuments, WebsiteMonitoringStatus, WebsiteObject } from 'src/app/test-transactions-client';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { defaultTagValues, fileExtensionToMimeType } from 'src/app/models';
import { GenericNotificationAction, SeverityOptions } from '@ls/common-ng-components';
import { environment } from 'src/environments/environment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-transaction-detail-page',
  templateUrl: './transaction-detail-page.component.html',
  styleUrls: ['./transaction-detail-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsDetailPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  
  @Input() transactionId!: string;

  public transactionState$: Observable<TransactionState> = this.store.select(selectTransactionsNode);
  public screenshotsState$: Observable<any[]> = this.store.select(selectTransactionDetailScreenshots);
  public attachmentsState$: Observable<any[]> = this.store.select(selectTransactionDetailAttachments);
  public editDetails = false;
  public formTransactionDetail!: FormGroup;
  public transaction: GetTransaction200Response | undefined;
  public allCategories: CategoryObject[] | undefined;
  public allTags: string[] = defaultTagValues;
  public website: WebsiteObject | undefined;
  public loading = true;
  public zipping = false;

  public fileMenuItems!: MenuItem[];
  public activeFileMenuItem: MenuItem | undefined;
  public files: File[] | undefined;
  public sourceOptions = Object.values(GetTransaction200Response.SourceEnum);
  public transactionStatusOptions = Object.values(GetTransaction200Response.TransactionStatusEnum);
  public transactionCurrencyOptions = Object.values(GetTransaction200Response.TransactionCurrencyEnum);
  public persistentMonitoringOptions = Object.values(WebsiteMonitoringStatus);
  public issuerLocationOptions = Object.values(GetTransaction200Response.IssuerLocationEnum);
  public cardholderLocationOptions = Object.values(GetTransaction200Response.CardholderLocationEnum);
  public categoryOptions: CategoryObject[] = [];
  public screenshots: any[] = [];
  public attachments: any[] = [];
  public imageViewerVisible = false;
  public currentImage = 0;
  public screenshotBeingDragged: any = undefined;
  public hoveredScreenshot: any = undefined;
  public lastScreenhsotUrlRefresh?: Date = undefined;
  public lastAttachmentUrlRefresh?: Date = undefined;
  public screenshotReorderPosition: 'before' | 'after' | undefined;
  public screenshotReorderPending = false;
  public showDeleteConfirmation = false;
  public showSaveConfirmation = false;
  public mylsLink = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private messageService: MessageService,
    ) {}
 
  ngOnInit(): void {
    this.fileMenuItems = [
      { label: 'Screenshots' },
      { label: 'Attachments'}
    ];
    this.activeFileMenuItem = this.fileMenuItems[0];
    const thirtyMinutesAgo = new Date();
    thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30);

    this.store.dispatch(TransactionsActions.getTransaction({id: this.transactionId}));
    this.transactionState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
          this.transaction = state.transactionDetail;
          this.mylsLink = `${environment.MYLS_HOST}/#/services/test-transactions?id=${this.transaction?.id}&showDetails=${this.transaction?.id}`
          this.website = state.transactionWebsiteDetail;
          this.allCategories = state.allCategories;
          this.categoryOptions = [...(this.allCategories ?? []).map((c) => {return {name: c.name as string, id: c.id}})].sort((categoryA, categoryB) => categoryA.name.localeCompare(categoryB.name));
          this.loading = state.transactionDetailPending || state.transactionWebsiteDetailPending;
          this.lastScreenhsotUrlRefresh = state.lastScreenhsotUrlRefresh;
          this.lastAttachmentUrlRefresh = state.lastAttachmentUrlRefresh;
          this.screenshotReorderPending = state.reorderScreenshotsPending;
          const tagsFromState = (state.transactionsUniqueValues?.columns.recordTags ?? []).filter((t) => !defaultTagValues.includes(t));
          const tagsFromTransaction = (state.transactionDetail?.recordTags ?? []).filter((t) => !defaultTagValues.includes(t));
          this.allTags = [...defaultTagValues, ...tagsFromState, ...tagsFromTransaction].sort();
          this.populateFormFromState();
        });
    this.screenshotsState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((screenshots) =>{
          let sortedScreenshots: any[] = [];
          if(screenshots.length > 0 && this.transaction?.id && !this.lastScreenhsotUrlRefresh){
            this.store.dispatch(TransactionsActions.downloadAllScreenshots({transactionId: this.transaction.id}));
          }
          sortedScreenshots = [...screenshots.slice().sort((a,b) => {
            if (!isNaN(a.order) && !isNaN(b.order)) {
              if(a.order < b.order){
                return -1;
              } else if (b.order < a.order) {
                return 1;
              }
            }
            if (a.createdAt && b.createdAt) {
              if (a.createdAt < b.createdAt) {
                return -1;
              } else if (b.createdAt < a.createdAt) {
                return 1;
              }
            }
            return 0;
          })]
          this.screenshots = [...sortedScreenshots];
        });
    this.attachmentsState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((attachments) =>{
          this.attachments = [...attachments];
          if(attachments.length > 0 && this.transaction?.id && !this.lastAttachmentUrlRefresh){
            this.store.dispatch(TransactionsActions.downloadAllAttachments({transactionId: this.transaction.id}));
          }
        });
    if(!this.allCategories || this.allCategories.length === 0){
      this.store.dispatch(TransactionsActions.getAllCategories());
    }
    this.configureForms();
  }
  onFileTabChange(event: MenuItem) {
    this.activeFileMenuItem = event;
  }

  onFilesChanged(event: File[]){
    this.files = event;
  }

  onUploadFile(transactionId: string){
    if(this.activeFileMenuItem === this.fileMenuItems[0]){
      (this.files ?? []).forEach((file, uploadIndex) => {
        const orderOffset = this.screenshots.length
        this.store.dispatch(TransactionsActions.getScreenshotUploadUrl({file: file, transactionId: transactionId, fileName: file.name as string, relativePosition: uploadIndex + orderOffset}));
      });
    }
    else if(this.activeFileMenuItem === this.fileMenuItems[1]){
      (this.files ?? []).forEach(file => {
        this.store.dispatch(TransactionsActions.getAttachmentUploadUrl({file: file, transactionId: transactionId, fileName: file.name as string}));
      });
    }
    this.files = undefined;
  }

  deselectTag(tag: string){
    this.formTransactionDetail.patchValue({
      recordTags: this.formTransactionDetail.get('recordTags')?.value.filter((t: string) => t !== tag)
    });
  }

  closeDetails() {
    this.router.navigate(['transactions']);
  }

  getWebsiteCategoryId(): string{
    return this.allCategories?.find((c) => c.id === this.website?.categoryId)?.id ?? '';
  }

  public onEdit = () => {
    this.populateFormFromState();
    this.editDetails = true;
    this.formTransactionDetail.enable();
  }

  public onEditCancel = () => {
    this.populateFormFromState();
    this.editDetails = false;
    this.formTransactionDetail.disable();
  }

  public onSave = () => {
    this.editDetails = false;
    this.showSaveConfirmation = false;
    const formValue = {...this.formTransactionDetail.value};
    this.store.dispatch(TransactionsActions.updateWebsite(
      {
        request:{
          id: this.transaction?.websiteId as string,
          UpdateAWebsiteRequest: {
            ...formValue,
            accountId: this.transaction?.accountId
          }
        }
      }
    ));
    this.store.dispatch(TransactionsActions.updateTransaction(
      {
        request:{
          id: this.transaction?.id as string,
          UpdateTransactionRequest: {
            ...formValue
          }
        }
      }
    ));
    this.formTransactionDetail.disable();
  }

  public onDeleteTransaction = () => {
    this.showDeleteConfirmation = false;
    this.store.dispatch(TransactionsActions.deleteTransaction({transactionId: this.transaction!.id }));
  }

  public openScreenshot = (screenshot: any) => {
    this.imageViewerVisible = true;
    this.screenshots.forEach((s) => {
      this.store.dispatch(TransactionsActions.downloadScreenshot({transactionId: this.transaction?.id as string, screenshotId: s.id}))
    })
    this.currentImage = screenshot.order;
  }

  public downloadAllDocuments = () => {
    let files: TransactionDocuments[] = [];
    let type: 'screenshot' | 'attachment' = 'screenshot';
    let folderName: string = this.transaction?.websiteUrl ?? '';
    
    if(this.activeFileMenuItem === this.fileMenuItems[0]){
      files = this.screenshots;
      folderName = `${folderName}-screenshots`;
    }
    else if(this.activeFileMenuItem === this.fileMenuItems[1]){
      files = this.attachments;
      type = 'attachment';
      folderName = `${folderName}-attachments`;
    }

    this.onDownloadFile({
      files,
      type,
      zip: true,
      folderName
    });

  }

  public onDownloadFile($event: {
    files: TransactionDocuments[];
    type: 'screenshot' | 'attachment';
    zip?: boolean;
    folderName: string;
  }) {
    let filesWithUrls = $event.files.filter((f) => f.url);
    
    if (filesWithUrls.length > 0) {
      if ($event.zip) {
        filesWithUrls = filesWithUrls.map((url, index) => {
          // if the url has the "order" property, use that as index, otherwise use the fallback index
          const urlOrder = url.order !== undefined && isFinite(url.order) ? url.order : index;
          return {...url, order: urlOrder};
        });
        this.downloadAndZipImages(filesWithUrls as { url: string; name: string; order: number }[], $event.folderName);
      } else {
        filesWithUrls.forEach((file) => this.downloadFromUrl(file as { url: string; name: string }));
      }
    }

  }

  getSortedUrls(urls: { name: string; order: number, response: ArrayBuffer }[]) {
    return [...(urls ?? [])].sort((urlA, urlB) => urlA.order - urlB.order);
  }

  downloadAndZipImages(imageUrls: { url: string; name: string; order: number }[], zipFileName: string): void {
    const zip = new JSZip();

    let urlResponses: { name: string; order: number, response: ArrayBuffer}[] = [];
    this.zipping = true;
    const imagePromises = imageUrls.map((image) => {
      return new Promise((resolve, reject) => {
        this.http
          .get(image.url, { responseType: 'arraybuffer' })
          .pipe(
            take(1),
            catchError((err: HttpErrorResponse) => {
              console.error('Download failed for image:', image.url, err);
              return of(null as unknown as ArrayBuffer); // Handle download failure
            }),
          )
          .toPromise()
          .then((response) => {
            if (response) {
              urlResponses.push({ order: image.order, name: image.name, response });
              resolve(urlResponses);
            }
          });
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        urlResponses = this.getSortedUrls(urlResponses);
        urlResponses.map((url, index) => {
          const urlIndex = url.order !== undefined && isFinite(url.order) ? url.order : index;
          zip.file(`${urlIndex}_${url.name}`, url.response, { binary: true });
        });
      })
      .then(() => {
        return zip.generateAsync({ type: 'blob' });
      })
      .then((content) => {
        saveAs(content, `${zipFileName}.zip`);
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.SUCCESS,
            summary: 'Files download success',
            detail: `Files successfully downloaded`,
            sticky: false,
            life: 5000
          }),
        );
        this.zipping = false;
      })
      .catch((error) => {
        console.error('Error generating ZIP file:', error);
        this.store.dispatch(
          GenericNotificationAction({
            severity: SeverityOptions.ERROR,
            summary: 'Files download error',
            detail: `Error Downloading files`,
            sticky: false,
            life: 5000,
          }),
        );
        this.zipping = false;
      });
  }

  downloadFromUrl(url: { url: string; name: string }) {
    const fileType = url.url.split('?')[0].split('.').pop();
    const type = fileExtensionToMimeType[fileType as string];

    // It may seem strange to call http directly in a component, but I don't want to keep the blob in memory (it should be free for garbage collection)
    // Creating a state-based action here would require we either clear out the blob, which is clunky, or keep it in memory (in state), which is undesirable.
    this.http
      .get(url.url, { observe: 'body', responseType: 'arraybuffer' })
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.store.dispatch(
            GenericNotificationAction({
              severity: SeverityOptions.ERROR,
              summary: 'Downloading failed.',
              detail: err?.error?.message || err?.message || err || 'Unknown Error',
              sticky: false,
              blocking: false,
            }),
          );
          return of(undefined);
        }),
      )
      .subscribe((response?: ArrayBuffer) => {
        if (response) {
          const blob = new Blob([response], { type });
          this.saveBlob(blob, url.name);
        }
      });
    }

  saveBlob(blob: Blob, name: string) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    document.body.removeChild(link);
  }

  public currentImageChanged = ($event: number) => {
    this.currentImage = $event;
  }

  public dragStart = (screenshot: any) => {
    this.screenshotBeingDragged = screenshot;
  }

  public dragEnd = (event: DragEvent) => {
    this.screenshotBeingDragged = undefined;
    this.hoveredScreenshot = undefined;
  }

  public enteredDropBeforeZone = (screenshot: any) => {
    this.hoveredScreenshot = screenshot;
    this.screenshotReorderPosition = 'before';
  }
  public enteredDropAfterZone = (screenshot: any) => {
    this.hoveredScreenshot = screenshot;
    this.screenshotReorderPosition = 'after';
  }

  public reorderScreenshots = () => {
    const transactionId = this.transaction?.id as string;
    const orderedScreenshots = [...this.screenshots];
    const clearedScreenshots = orderedScreenshots.filter((s) => {
        return s.id !== this.screenshotBeingDragged.id
      });
    if(this.screenshotReorderPosition === 'before'){
      if(this.screenshots.indexOf(this.hoveredScreenshot) === this.screenshots.indexOf(this.screenshotBeingDragged)+1){
        return;
      }
      clearedScreenshots.splice(clearedScreenshots.indexOf(this.hoveredScreenshot), 0, this.screenshotBeingDragged);
    }
    else if(this.screenshotReorderPosition === 'after'){
      if(this.screenshots.indexOf(this.hoveredScreenshot) === this.screenshots.indexOf(this.screenshotBeingDragged)-1){
        return;
      }
      clearedScreenshots.splice(clearedScreenshots.indexOf(this.hoveredScreenshot)+1, 0, this.screenshotBeingDragged);
    }
    this.store.dispatch(TransactionsActions.reorderScreenshots({transactionId, orderedScreenshots: clearedScreenshots}));
    this.screenshotBeingDragged = undefined;
    this.hoveredScreenshot = undefined;
  }

  public deleteScreenshot = (transactionId: string, screenshotId: string) => {
    this.store.dispatch(TransactionsActions.deleteScreenshot({ transactionId, screenshotId }));
    this.screenshots = this.screenshots.filter((s) => s.id !== screenshotId);
  }

  public deleteAttachment = (transactionId: string, attachmentId: string) => {
    this.store.dispatch(TransactionsActions.deleteAttachment({ transactionId, attachmentId }));
    this.attachments = this.attachments.filter((s) => s.id !== attachmentId);
  }

  public populateFormFromState = () => {
    this.formTransactionDetail?.reset();
    this.formTransactionDetail?.patchValue({
      ...this.transaction,
      ...this.website,
      id: this.transaction?.id,
      categoryId: this.getWebsiteCategoryId(),
    });
  }

  public onCopyLinkClick(link: string) {
    navigator.clipboard.writeText(link);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Link copied to clipboard' });
  }

  public configureForms = (): void => {
    this.formTransactionDetail = this.formBuilder.group({
      internalNote: [],
      analysisNote: [''],
      recordTags: [],
      source: ['', Validators.required],
      monitoringStatus: ['', Validators.required],
      requestId: [],
      legitscriptId: [],
      id: [{value: '', disabled: true}],
      categoryId: ['', Validators.required],
      network: [],
      clientSub: [],
      purchaseAmount: [],
      transactionUSDAmount: [],
      transactionCurrency: [],
      transactionDescription: [],
      transactionStatus: ['', Validators.required],
      transactionDate: [],
      ccLast4: [],
      analysisDate: [],
      paymentWebsite: [],
      transactionAmount: [],
      merchantDescriptor: [],
      acquirerBin: [],
      acquirerBid: [],
      acquirerCountry: [],
      acquirerRegion: [],
      merchantCategoryCode: [],
      cardAcceptorId: [],
      rightsHolder: [],
      acquirerName: [],
      terminalId: [],
      transactionId: [],
      agent: [],
      merchantState: [],
      merchantCountry: [],
      rightsHolderContactName: [],
      rightsHolderContactPhone: [],
      rightsHolderContactEmail: [],
      rightsHolderContactAddress: [],
      posConditionCode: [],
      posEntryMode: [],
      merchantCity: [],
      mccCategory: [],
      issuerLocation: [],
      cardholderLocation: [],
    })
    this.formTransactionDetail.disable();
  }
  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
