/**
 * Transactions API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TransactionStatuses = 'Pending' | 'Tested' | 'Tested - Hit' | 'Tested - No Hit' | 'Not Tested - Dysfunctional' | 'Not Tested - KYC Requirements' | 'Not Tested - Not Reportable' | 'Not Tested - Parked/Offline' | 'Not Tested - Redirect' | 'Not Tested - Review Only' | 'Not Tested - Non-Client CCs only' | 'Not Tested - No CCs' | 'Not Tested - Other' | 'Not Tested - SMS Verification' | 'Not Tested - Target Jurisdiction Blocked' | 'Ordered';

export const TransactionStatuses = {
    Pending: 'Pending' as TransactionStatuses,
    Tested: 'Tested' as TransactionStatuses,
    Tested___Hit: 'Tested - Hit' as TransactionStatuses,
    Tested___No_Hit: 'Tested - No Hit' as TransactionStatuses,
    Not_Tested___Dysfunctional: 'Not Tested - Dysfunctional' as TransactionStatuses,
    Not_Tested___KYC_Requirements: 'Not Tested - KYC Requirements' as TransactionStatuses,
    Not_Tested___Not_Reportable: 'Not Tested - Not Reportable' as TransactionStatuses,
    Not_Tested___Parked_Offline: 'Not Tested - Parked/Offline' as TransactionStatuses,
    Not_Tested___Redirect: 'Not Tested - Redirect' as TransactionStatuses,
    Not_Tested___Review_Only: 'Not Tested - Review Only' as TransactionStatuses,
    Not_Tested___Non_Client_CCs_only: 'Not Tested - Non-Client CCs only' as TransactionStatuses,
    Not_Tested___No_CCs: 'Not Tested - No CCs' as TransactionStatuses,
    Not_Tested___Other: 'Not Tested - Other' as TransactionStatuses,
    Not_Tested___SMS_Verification: 'Not Tested - SMS Verification' as TransactionStatuses,
    Not_Tested___Target_Jurisdiction_Blocked: 'Not Tested - Target Jurisdiction Blocked' as TransactionStatuses,
    Ordered: 'Ordered' as TransactionStatuses
};

