import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public auth: AuthService, public router: Router) {
  }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((value) => {
      if (value) {
        console.log('User is authenticated, redirecting to list...');
        this.router.navigate(['/loading']);
      }
    });
  }

}
