import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TransactionState, TransactionsActions } from 'src/app/store';
import { selectTransactionsNode } from 'src/app/store/transactions/transactions.selectors';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CategoryObject, CreateTransactionRequest, GetTransaction200Response, WebsiteMonitoringStatus, WebsiteObject } from 'src/app/test-transactions-client';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { defaultTagValues } from 'src/app/models';

@Component({
  selector: 'app-transaction-create-page',
  templateUrl: './transaction-create-page.component.html',
  styleUrls: ['./transaction-create-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionCreatePageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  
  @Input() transactionId!: string;

  public transactionState$: Observable<TransactionState> = this.store.select(selectTransactionsNode);
  public editDetails = false;
  public formTransactionCreate!: FormGroup;
  public allTags: string[] = defaultTagValues;
  public allCategories: CategoryObject[] = [];
  public allAccounts: any[] = [];
  public loading = false;

  public sourceOptions = Object.values(CreateTransactionRequest.SourceEnum);
  public transactionStatusOptions = Object.values(CreateTransactionRequest.TransactionStatusEnum);
  public transactionCurrencyOptions = Object.values(CreateTransactionRequest.TransactionCurrencyEnum);
  public persistentMonitoringOptions = Object.values(WebsiteMonitoringStatus);
  public issuerLocationOptions = Object.values(CreateTransactionRequest.IssuerLocationEnum);
  public cardholderLocationOptions = Object.values(GetTransaction200Response.CardholderLocationEnum);
  public categoryOptions: string[] = [];
  public clientOptions: any[] = []

  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    ) {}
 
  ngOnInit(): void {
    this.transactionState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
          this.loading = state.isBusy;
          this.allCategories = state.allCategories;
          this.allAccounts = state.allAccounts;
          this.clientOptions = [...state.allAccounts];
          this.categoryOptions = [...state.allCategories.map((c) => c.name as string)].sort();
        });
    if(!this.allCategories || this.allCategories.length === 0){
      this.store.dispatch(TransactionsActions.getAllCategories());
    }
    if(!this.allAccounts || this.allAccounts.length === 0){
      this.store.dispatch(TransactionsActions.getAllAccounts());
    }
    this.configureForms();
  }

  close() {
    this.router.navigate(['transactions']);
  }
  deselectTag(tag: string){
    this.formTransactionCreate.patchValue({
      recordTags: this.formTransactionCreate.get('recordTags')?.value.filter((t: string) => t !== tag)
    });
  }

  public onSave = () => {
    const request: {[key: string]: any} = {};
    Object.keys(this.formTransactionCreate.value).forEach((key) => {
      if(key === 'client'){
        request['client'] = this.formTransactionCreate.value[key].name;
        request['accountId'] = this.formTransactionCreate.value[key].id;
      }
      else if (key === 'category'){
        request['categoryId'] = this.allCategories.find((c) => c.name === this.formTransactionCreate.value[key])?.id;
      }
      else if(this.formTransactionCreate.value[key]){
        request[key] = this.formTransactionCreate.value[key];
      }
    }) 
    this.store.dispatch(TransactionsActions.createTransaction({ request: {...request} as CreateTransactionRequest}));
  }


  public configureForms = (): void => {
    this.formTransactionCreate = this.formBuilder.group({
      client: ['', Validators.required],
      clientSub: [''],
      websiteUrl: ['', Validators.required],
      internalNote: [],
      analysisNote: [''],
      recordTags: [],
      source: ['', Validators.required],
      monitoringStatus: [],
      requestId: [],
      legitscriptId: [],
      id: [],
      category: ['', Validators.required],
      network: [],
      purchaseAmount: [],
      transactionUSDAmount: [],
      transactionCurrency: [],
      transactionDescription: [],
      transactionStatus: ['Pending', Validators.required],
      transactionDate: [],
      ccLast4: [],
      analysisDate: [],
      paymentWebsite: [],
      transactionAmount: [],
      merchantDescriptor: [],
      acquirerBin: [],
      acquirerBid: [],
      acquirerCountry: [],
      acquirerRegion: [],
      merchantCategoryCode: [],
      cardAcceptorId: [],
      rightsHolder: [],
      acquirerName: [],
      terminalId: [],
      transactionId: [],
      agent: [],
      merchantState: [],
      merchantCountry: [],
      rightsHolderContactName: [],
      rightsHolderContactPhone: [],
      rightsHolderContactEmail: [],
      rightsHolderContactAddress: [],
      posConditionCode: [],
      posEntryMode: [],
      merchantCity: [],
      mccCategory: [],
      issuerLocation: [],
      cardholderLocation: [],
    })
  }
  closeDetails() {
    this.router.navigate(['transactions']);
  }
  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
