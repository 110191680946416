import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MessageService } from "primeng/api";
import { switchMap, map } from "rxjs";
import { TransactionsActions } from "../store";

@Injectable()
export class ErrorEffects {

    constructor(
        private actions$: Actions,
        private messageService: MessageService,
        ) {}

    handleListTransactionsError$ = createEffect(() => this.actions$
    .pipe(
        ofType(TransactionsActions.listError),
        map((action) => {
                this.messageService.add({
                    key:'genericNotification',
                    severity: 'error',
                    summary: 'An error occurred fetching transactions',
                    detail: action.error,
                    sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );

    handleUpdateWebsiteError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.updateWebsiteError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while updating website data',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );

    handleCreateTransactionError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.createTransactionError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while creating a transaction',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );

    handleUpdateTransactionError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.updateTransactionError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while updating transaction data',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );

    handleGetTransactionError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.getTransactionError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while getting transaction data',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );
    
    handleUploadError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.uploadFileError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while uploading a file',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );

    handleDeleteTransactionError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.deleteTransactionError),
            map((action) => {
                    this.messageService.add({
                        key:'genericNotification',
                        severity: 'error',
                        summary: 'An error occurred while deleting transaction',
                        detail: action.error,
                        sticky: true
                    });
                }
            ),
        ),
        {dispatch: false}
    );
    handleBulkUploadError$ = createEffect(() => this.actions$
        .pipe(
            ofType(TransactionsActions.bulkUploadError),
            map((action) => {
                this.messageService.add({
                    key:'genericNotification',
                    severity: 'error',
                    summary: 'Something was wrong with your upload',
                    detail: action.error,
                    sticky: true,
                })
            })
        ),
        {dispatch: false})
}
