import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { S3BucketFields } from '../models';
@Injectable()
export class UploadService {

  constructor(
    private http: HttpClient,
  ) {}

  public uploadFile(file: File, url: string, fields: S3BucketFields): Observable<unknown> {
        const formData = new FormData();

          // build the multipart form that S3 requires
          Object.keys(fields).forEach((field) => formData.append(field, fields[field as keyof S3BucketFields]));
          formData.append('file', file, file.name); // add the file
          return this.http.post<any>(url, formData); // upload to S3

  }
}