export interface S3BucketResponse {
    name: string,
    id: string,
    url: string,
    fields: S3BucketFields
}

export interface S3BucketFields { 
    Key: string,
    ['x-amz-meta-username']: string,
    bucket: string,
    ['X-Amz-Algorithm']: string,
    ['X-Amz-Credential']: string,
    ['X-Amz-Date']: string,
    ['X-Amz-Security-Token']: string,
    Policy: string,
    ['X-Amz-Signature']: string
    ['X-Amz-Server-Side-Encryption']: string,
    ['X-Amz-Server-Side-Encryption-aws-kms-key-id']: string,
    acl: string
}