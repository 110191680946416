<div class="transaction-detail-component">
    <ng-container *ngIf="(transactionState$ | async) as transactionState">
    <ng-container *ngIf="transactionState.transactionDetail as transaction">
    <ng-container *ngIf="transactionState.transactionWebsiteDetail as website"> 
        <div class="navbar">
            <p-button
                class="return-button"
                icon="pi pi-home"
                iconPos="left"
                label="Transaction List"
                [rounded]="true"
                [text]="true"
                size="small"
                (onClick)="closeDetails()"
            ></p-button>
            <div class="">
                <i class="pi pi-chevron-right"></i>
            </div>           
            <div *ngIf="!loading" class="breadcrumb">
                {{ transaction.websiteUrl }}
            </div>
            <p-skeleton *ngIf="loading" height="20px" width="250px"></p-skeleton>
            <div class="navbar-edit-buttons">
                <p-button *ngIf="!editDetails"
                    styleClass="edit-transaction-button"
                    label="Edit"
                    [text]="true"
                    icon="pi pi-pencil"
                    iconPos="right"
                    size="small"
                    (onClick)="onEdit()"
                ></p-button>
                <p-button *ngIf="editDetails"
                    styleClass="save-transaction-button"
                    label="Save Transaction"
                    [disabled]="formTransactionDetail.invalid"
                    [text]="true"
                    icon="pi pi-save"
                    iconPos="left"
                    size="small"
                    (onClick)="showSaveConfirmation = true"
                ></p-button>
                <p-button *ngIf="editDetails"
                    label="Cancel"
                    class="cancel-button"
                    [text]="true"
                    icon="pi pi-times"
                    iconPos="left"
                    size="small"
                    (onClick)="onEditCancel()"
                ></p-button>
            </div>
        </div>
        <form [formGroup]="formTransactionDetail">
            <div class="left-column">
                <p-card header="General Information">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Client</div>
                                <div class="field-input" *ngIf="!loading">{{transaction.client}}</div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Client Sub</div>
                                <div class="field-input">
                                    <input *ngIf="!loading" type="text" class="p-inputtext-sm" pInputText formControlName="clientSub">
                                </div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Website</div>
                                <div *ngIf="!loading"><a href="{{transaction.websiteUrl}}" target="_blank">{{transaction.websiteUrl}}</a></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Customer Link</div>
                                <div class="myls-link-container" *ngIf="!loading">
                                    <a href="{{mylsLink}}" target="_blank">MyLS</a>
                                    <p-button icon="pi pi-copy" [rounded]="true" [text]="true" size="small" (onClick)="onCopyLinkClick(mylsLink)"></p-button>
                                </div>
                                <p-skeleton class="link-skeleton" width="95%" height="40px" *ngIf="loading" class="mt-3"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Internal Note</div>
                                <div *ngIf="!loading" class="field-input"><textarea rows="4" pInputTextarea formControlName="internalNote"></textarea></div>
                                <p-skeleton width="95%" height="100px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Analysis Note</div>
                                <div *ngIf="!loading" class="field-input"><textarea rows="4" pInputTextarea formControlName="analysisNote"></textarea></div>
                                <p-skeleton width="95%" height="100px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Tracking Information">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">LS Internal UID</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="legitscriptId"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Request Id</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="requestId"></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Record ID</div>
                                <div *ngIf="!loading" class="field-input">
                                    <span>{{ transaction.id }}</span>
                                </div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field"  [class.disabled-cursor]="!editDetails">
                                <div  class="field-name">Record Tags</div>
                                <div *ngIf="!loading" class="field-input">
                                    <p-multiSelect
                                        styleClass="{{editDetails ? 'multiselect-edit' : 'readonly-dropdown'}}"
                                        [options]="allTags"
                                        formControlName="recordTags"
                                        [showHeader]="false"
                                        [maxSelectedLabels]="10"
                                        display="chip">
                                    </p-multiSelect>
                                </div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Source</div>
                                <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="source" [options]="sourceOptions"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Persistent Monitoring</div>
                                <div *ngIf="!loading" class="field-input" [ngStyle]="{'cursor': editDetails ? 'auto' : 'not-allowed'}">
                                    <p-dropdown 
                                        styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" 
                                        formControlName="monitoringStatus" 
                                        [options]="persistentMonitoringOptions">
                                    </p-dropdown>
                                </div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">

                            <div class="column-spacer"></div> 
                            <div class="column-spacer"></div>
                        </div>
                    </div>
                </p-card>
                <p-card header="LegitScript Data">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Category</div>
                                <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="categoryId" [filter]="true" [options]="categoryOptions" optionLabel="name" optionValue="id"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Network</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="network"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Testing Details">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Transaction Status</div>
                                <div *ngIf="!loading" class="field-input">
                                    <p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="transactionStatus" [options]="transactionStatusOptions"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Analyzed Date</div>
                                <div *ngIf="!loading" class="field-input"><p-calendar dateFormat="yy-mm-dd" formControlName="analysisDate"></p-calendar></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Card Last 4 Digits</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="ccLast4"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Payment Website</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="paymentWebsite"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Purchase Amount</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="purchaseAmount"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Currency</div>
                                <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="transactionCurrency" [options]="transactionCurrencyOptions"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">To USD</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionUSDAmount"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Transaction Item</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionDescription"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Cardholder Location</div>
                                <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="cardholderLocation" [options]="cardholderLocationOptions"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name" [class.disabled-cursor]="!editDetails">Issuer Location</div>
                                <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="issuerLocation" [options]="issuerLocationOptions"></p-dropdown></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Transaction Date</div>
                                <div *ngIf="!loading" class="field-input"><p-calendar dateFormat="yy-mm-dd" formControlName="transactionDate"></p-calendar></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="column-spacer"></div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Transaction Details">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Transaction Amount</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionAmount"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Transaction ID / ARN</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionId"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">MCC</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCategoryCode"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">MCC Category</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="mccCategory"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">POS Condition Mode</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="posConditionCode"></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">POS Entry Mode</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="posEntryMode"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Terminal ID</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="terminalId"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Card Acceptor ID</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="cardAcceptorId"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Agent (TPA)</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="agent"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Acquirer Details">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Acquirer Name</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerName"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">BIN</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerBin"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">BID</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerBid"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Country</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerCountry"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Region</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerRegion"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Merchant Details">
                    <div class="underline"></div>
                    <div class="detail-row">
                        <div class="detail-field" [class.disabled-cursor]="!editDetails">
                            <div class="field-name">City</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCity"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field" [class.disabled-cursor]="!editDetails">
                            <div class="field-name">State</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantState"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field" [class.disabled-cursor]="!editDetails">
                            <div class="field-name">Country</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCountry"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field" [class.disabled-cursor]="!editDetails">
                            <div class="field-name">Descriptor</div>
                            <div *ngIf="!loading" class="field-input-full-width"><textarea rows="4" pInputTextarea formControlName="merchantDescriptor"></textarea></div>
                            <p-skeleton width="98%" height="100px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                </p-card>
                <p-card header="Rights Holder Details" class="last-card">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Rights Holder</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolder"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Contact Name</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactName"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Contact Phone</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactPhone"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Contact Email</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactEmail"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field" [class.disabled-cursor]="!editDetails">
                                <div class="field-name">Contact Address</div>
                                <div *ngIf="!loading" class="field-input-full-width"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactAddress"/></div>
                                <p-skeleton width="99%" height="40px" *ngIf="loading"></p-skeleton>
                                <div class="column-spacer"></div>
                                <div class="column-spacer"></div>
                                <div class="column-spacer"></div>
                            </div>
                        </div>
                    </div>
                </p-card>
            </div>
            <div class="right-column">
                <ls-upload *ngIf="transactionState.fileUploadState.uploadsPending === 0 && this.activeFileMenuItem === this.fileMenuItems[0]"
                    accept=".jpg,.png"
                    (filesChanged)="onFilesChanged($event)"
                    [multiple]="true"
                    [maxFileSize]="1.573e+7"
                ></ls-upload>
                <ls-upload *ngIf="transactionState.fileUploadState.uploadsPending === 0 && this.activeFileMenuItem === this.fileMenuItems[1]"
                    accept=".xls,.xlsx,.csv,.doc,.docx,.pdf,.HAR"
                    (filesChanged)="onFilesChanged($event)"
                    [multiple]="true"
                    [maxFileSize]="100000000"
                ></ls-upload>
                <div class="file-upload-loading-spinner" *ngIf="transactionState.fileUploadState.uploadsPending > 0">
                    <i class="pi pi-spin pi-spinner"></i>
                </div>
                <p-button 
                    [loading]="transactionState.fileUploadState.uploadsPending > 0"
                    size="small"
                    label="Upload {{this.activeFileMenuItem === this.fileMenuItems[0] 
                        ? 'Screenshot' : 'Attachment'}}"
                    (onClick)="onUploadFile(transaction.id)"
                    [disabled]="!files"></p-button>
                <p-tabMenu 
                    [model]="fileMenuItems"
                    [activeItem]="activeFileMenuItem"
                    (activeItemChange)="onFileTabChange($event)"
                    ></p-tabMenu>
                <div *ngIf="activeFileMenuItem === fileMenuItems[0]">
                    <div *ngIf="screenshots.length === 0">Currently No Screenshots</div>
                    <div *ngIf=" screenshots.length > 0 && !screenshotReorderPending">
                        <div class="screenshot-list-card-container" *ngFor="let screenshot of screenshots">
                            <div 
                                *ngIf="hoveredScreenshot?.id === screenshot.id
                                && screenshotBeingDragged !== screenshot.id
                                && screenshotReorderPosition === 'before'
                                && screenshots.indexOf(screenshotBeingDragged) !== screenshots.indexOf(screenshot)-1"
                                pDroppable
                                dragEffect="link"
                                (onDrop)="reorderScreenshots()"
                                (onDragEnter)="enteredDropBeforeZone(screenshot)"
                                class="card-reorder-preview before"></div>
                            <div pDraggable dragEffect="link" (onDragStart)="dragStart(screenshot)" (onDragEnd)="dragEnd($event)" dragHandle=".pi-bars" class="screenshot-list-card">
                                <div 
                                    *ngIf="screenshotBeingDragged
                                    && screenshotBeingDragged !== screenshot"
                                    pDroppable
                                    dragEffect="link"
                                    (onDrop)="reorderScreenshots()"
                                    (onDragEnter)="enteredDropBeforeZone(screenshot)"
                                    class="drop-before"></div>
                                <div 
                                    *ngIf="screenshotBeingDragged
                                    && screenshotBeingDragged !== screenshot"
                                    pDroppable 
                                    dragEffect="link"
                                    (onDrop)="reorderScreenshots()"
                                    (onDragEnter)="enteredDropAfterZone(screenshot)"
                                    class="drop-after"></div>
                                <div class="card-left">
                                    <i class="pi pi-bars" ></i>
                                    <p-button label="{{screenshot.name | midTruncate:50:15}}" [text]="true" (onClick)="openScreenshot(screenshot)"></p-button>
                                </div>
                                <div class="card-right">
                                    <p-button size="small" icon="pi pi-download" [text]="true" (onClick)="downloadFromUrl({url: screenshot.url, name: screenshot.name})"></p-button>
                                    <p-button size="small" icon="pi pi-trash" [text]="true" (onClick)="deleteScreenshot(transaction.id, screenshot.id)"></p-button>
                                </div>
                            </div>
                            <div 
                                *ngIf="hoveredScreenshot?.id === screenshot.id
                                && screenshotBeingDragged !== screenshot.id
                                && screenshotReorderPosition === 'after'
                                && screenshots.indexOf(screenshotBeingDragged) !== screenshots.indexOf(screenshot)+1"
                                pDroppable 
                                dragEffect="link"
                                (onDrop)="reorderScreenshots()"
                                (onDragEnter)="enteredDropAfterZone(screenshot)"
                                class="card-reorder-preview after"></div>
                        </div>
                    </div>
                    <div class="screenshot-list-skeleton" *ngIf="screenshotReorderPending">
                        <div *ngFor="let screenshot of screenshots">
                        <p-skeleton height="44px"></p-skeleton>
                        </div>
                    </div>
                </div>
                <div *ngIf="activeFileMenuItem === fileMenuItems[1]">
                    <div *ngIf="attachments.length === 0">Currently No Attachments</div>
                    <div *ngIf="attachments.length > 0">
                        <div class='attachment-list-card' *ngFor="let attachment of attachments">
                            <div class="card-left">
                                {{attachment.name | midTruncate:50:15}}
                            </div>
                            <div class="card-right">
                                <p-button size="small" icon="pi pi-download" [text]="true" (onClick)="downloadFromUrl({url: attachment.url, name: attachment.name})"></p-button>
                                <p-button size="small" icon="pi pi-trash" [text]="true" (onClick)="deleteAttachment(transaction.id, attachment.id)"></p-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="document-button-container">
                    <p-button [disabled]="zipping || loading || (this.activeFileMenuItem === this.fileMenuItems[0] && this.screenshots.length === 0) || (this.activeFileMenuItem === this.fileMenuItems[1] && this.attachments.length === 0)" icon="pi pi-download" iconPos="right" size="small" label="Download All {{this.activeFileMenuItem === this.fileMenuItems[0] ? 'Screenshots' : 'Attachments'}}" (onClick)="downloadAllDocuments()"></p-button>
                    <p-button [text]="true" icon="pi pi-trash" iconPos="right" size="small" label="Delete Transaction" (onClick)="showDeleteConfirmation = true"></p-button>
                </div>
                <div class="files-download-loading-spinner" *ngIf="zipping">
                    <i class="pi pi-spin pi-spinner"></i>
                </div>
            </div>
            <p-dialog
                header="Delete Transaction"
                [modal]="true"
                [resizable]="false"
                [draggable]="false"
                [style]="{ width: '550px'}"
                [(visible)]="showDeleteConfirmation"
                [closable]="false"
            >
                <div>
                    Are you sure you want to delete this transaction?
                </div>
                <div class="modal-button-container">
                    <p-button label="Cancel" [text]="true" size="small" (onClick)="showDeleteConfirmation = false"></p-button>
                    <p-button styleClass="confirm-button" label="Delete" severity="danger" size="small" (onClick)="onDeleteTransaction()"></p-button>
                </div>
            </p-dialog>
            <p-dialog
                header="Override Transaction"
                [modal]="true"
                [resizable]="false"
                [draggable]="false"
                [style]="{ width: '550px'}"
                [(visible)]="showSaveConfirmation"
                [closable]="false"
            >
                <div>
                    Are you sure you want to override this existing transaction?
                </div>
                <div class="modal-button-container">
                    <p-button label="Cancel" [text]="true" size="small" (onClick)="showSaveConfirmation = false"></p-button>
                    <p-button styleClass="confirm-button" label="Override" severity="danger" size="small" (onClick)="onSave()"></p-button>
                </div>
            </p-dialog>
        </form>
        <ls-image-carousel
            [visible]="imageViewerVisible"
            [currentImage]="currentImage"
            [images]="screenshots"
            (closeGallery)="imageViewerVisible = false"
            (currentImageChanged)="currentImageChanged($event)"
        ></ls-image-carousel>
    
    </ng-container>
    </ng-container>
    </ng-container>
</div>