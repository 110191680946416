export interface TransactionAttachment {
    id: string,
    name: string,
    createdAt: Date,
    updatedAt: Date,
    createdBy: Date,
    updatedBy: Date
}

export interface TransactionScreenshot extends TransactionAttachment{
    order: number,
}