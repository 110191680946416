export type ColumnConfig = {
    /** the value to display as the column header */
    headerValue: string;
    /** the field name for this property */
    fieldName: string;
    /** should this column be frozen? */
    frozen?: boolean;
    /** should this column be sortable?  */
    sortable?: boolean;
    /** should this column be filterable? */
    filterable?: boolean;
    /** the width class to assign to the column */
    width?: string;
    containsUrl?: boolean;
    renderAsTags?: boolean;
  };

export const transactionListColumnConfig: ColumnConfig[] = [
    {
        headerValue: 'Record ID',
        fieldName: 'id',
        sortable: true,
        width: 'm',
    },
    {
        headerValue: 'MyLS',
        fieldName: 'mylsLink',
        sortable: false,
        width: 's',
        containsUrl: false
    },
    {
        headerValue: 'Client',
        fieldName: 'client',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Client Sub',
        fieldName: 'clientSub',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Target Website',
        fieldName: 'websiteUrl',
        width: 'l',
        containsUrl: true,
    },
    {
        headerValue: 'Category',
        fieldName: 'category',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Source',
        fieldName: 'source',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Request ID',
        fieldName: 'requestId',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Transaction Date',
        fieldName: 'transactionDate',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Analyzed Date',
        fieldName: 'analysisDate',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Transaction Item',
        fieldName: 'transactionDescription',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Card Last 4 Digits',
        fieldName: 'ccLast4',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Transaction Amount',
        fieldName: 'transactionAmount',
        sortable: true,
        width: 'm',
    },
    {
        headerValue: 'Currency',
        fieldName: 'transactionCurrency',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'To USD',
        fieldName: 'transactionUSDAmount',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Payment Website',
        fieldName: 'paymentWebsite',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Merchant Descriptor',
        fieldName: 'merchantDescriptor',
        sortable: true,
        width: 'm',
    },
    {
        headerValue: 'Merchant State',
        fieldName: 'merchantState',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Merchant Country',
        fieldName: 'merchantCountry',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'MCC',
        fieldName: 'merchantCategoryCode',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Analysis Note',
        fieldName: 'analysisNote',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Internal Note',
        fieldName: 'internalNote',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Persistent Monitoring',
        fieldName: 'monitoringStatus',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Transaction ID / ARN',
        fieldName: 'transactionId',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Terminal ID',
        fieldName: 'terminalId',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Record Tags',
        fieldName: 'recordTags',
        renderAsTags: true,
        width: 'l',
    },
    {
        headerValue: 'Card Acceptor ID',
        fieldName: 'cardAcceptorId',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Rights Holder',
        fieldName: 'rightsHolder',
        sortable: true,
        width: 'l',
    },
    {
        headerValue: 'Agent (TPA)',
        fieldName: 'agent',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Acquirer Name',
        fieldName: 'acquirerName',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Acquirer BIN',
        fieldName: 'acquirerBin',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Acquirer BID',
        fieldName: 'acquirerBid',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Acquirer Country',
        fieldName: 'acquirerCountry',
        sortable: true,
        width: 's',
    },
    {
        headerValue: 'Acquirer Region',
        fieldName: 'acquirerRegion',
        sortable: true,
        width: 's',
    },
];

export const columnsToGet = [
    'transactionDate',
    'analysisDate',
    'transactionAmount',
    'transactionUSDAmount',
    'recordTags'
]
