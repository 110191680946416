import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CardModule } from 'primeng/card';

import { LegitscriptUiModule } from '@ls/common-ng-components';

import {AuthButtonComponent} from './auth0-button.component';
import { LayoutComponent } from './layout/layout.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { BulkUploadResultComponent } from './bulk-upload-result/bulk-upload-result.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AuthButtonComponent,
    LayoutComponent,
    BulkUploadComponent,
    BulkUploadResultComponent,
  ],
  imports: [
    AvatarModule,
    ButtonModule,
    CommonModule,
    DialogModule,
    FormsModule,
    InputTextareaModule,
    LegitscriptUiModule,
    MenuModule,
    TableModule,
    ToastModule,
    CardModule,
  ],
  exports: [
    AuthButtonComponent,
    BulkUploadComponent,
    BulkUploadResultComponent,
    LayoutComponent,
    CardModule,
  ],
  providers: [
    MessageService,
  ]
})
export class ComponentsModule { }
