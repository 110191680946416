<div class="layout-container">
    <div class="nav-container">

        <div class="logo-container">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
            >
                <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.9655 9.86433C21.3429 10.2146 20.5801 10.2146 19.9575 9.86433L17.2394 8.29689C16.6782 7.93787 16.3187 7.32491 16.2924 6.65941V3.46325C16.3275 2.79775 16.6782 2.18476 17.2394 1.82574L19.9312 0.275833C20.5713 -0.0919444 21.3517 -0.0919444 21.9918 0.275833L24.6837 1.82574C25.2449 2.18476 25.5956 2.79775 25.6306 3.46325V6.65941C25.6043 7.32491 25.2449 7.93787 24.6837 8.29689L21.9655 9.86433Z"
                fill="#CCECFC"
                />
                <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4341 9.86433C10.8115 10.2146 10.0487 10.2146 9.42614 9.86433L6.70797 8.29689C6.1468 7.93787 5.79606 7.32491 5.76099 6.65941V3.46325C5.7873 2.79775 6.1468 2.18476 6.70797 1.82574L9.39984 0.275833C10.0312 -0.0919444 10.8203 -0.0919444 11.4604 0.275833L14.1523 1.82574C14.7135 2.18476 15.0642 2.79775 15.0992 3.46325V6.65941C15.0729 7.32491 14.7135 7.93787 14.1523 8.29689L11.4341 9.86433Z"
                fill="#9ADBF9"
                />
                <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.1731 18.9715C5.55055 19.3218 4.78771 19.3218 4.16516 18.9715L1.44698 17.4041C0.885808 17.0451 0.535073 16.4321 0.5 15.7666V12.5705C0.526305 11.905 0.885808 11.292 1.44698 10.933L4.13886 9.38305C4.77018 9.01527 5.55932 9.01527 6.19941 9.38305L8.89129 10.933C9.45246 11.292 9.81195 11.905 9.83825 12.5705V15.7666C9.81195 16.4321 9.45246 17.0451 8.89129 17.4041L6.1731 18.9715Z"
                fill="#57CBF5"
                />
                <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4341 28.0695C10.8115 28.4198 10.0487 28.4198 9.42614 28.0695L6.70797 26.5021C6.1468 26.1431 5.79606 25.5301 5.76099 24.8646V21.6597C5.7873 20.9942 6.1468 20.3812 6.70797 20.0222L9.39984 18.4723C10.0312 18.1045 10.8203 18.1045 11.4604 18.4723L14.1523 20.0222C14.7135 20.3812 15.0642 20.9942 15.0992 21.6597V24.8646C15.0729 25.5301 14.7135 26.1431 14.1523 26.5021L11.4341 28.0695Z"
                fill="white"
                />
            </svg>
            <div>
                <h3>Test Transactions</h3>
            </div>
        </div>
        <div class="right-nav">
            <div class="user-container" *ngIf="auth.isAuthenticated$ | async">
                <p-menu #userMenu [model]="items" [popup]="true"></p-menu>
                <p-avatar
                    [label]="initials" 
                    styleClass="mr-2 icon-button" 
                    shape="circle"
                    (click)="userMenu.toggle($event)"
                    [style]="{ 'color': '#000', 'background-color': '#9bdbf9', 'border': '1px solid white' }"
                ></p-avatar>
            </div>
        </div>
    </div>
    <p-toast position="bottom-left"></p-toast>
</div>
<p-toast key="genericNotification" position="bottom-left"></p-toast>