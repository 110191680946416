import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppState, AuthService, User } from '@auth0/auth0-angular';
import { MenuItem, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  public user$: Observable<User | null | undefined> | undefined;
  public items: MenuItem[] | undefined;
  public showBulkUpload = false;
  public initials: string | undefined = '';

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user$;

    this.user$.subscribe(x => {
      this.initials = x?.name?.split(' ').map(y => y.toUpperCase().substring(0, 1)).join('');
    })

    this.items = [
      {
        label: 'Sign out',
        icon: 'pi pi-sign-out',
        command: () => this.onLogoutClick()
      }
    ];
  }

  onLogoutClick = () => {
    this.auth.logout({ logoutParams: { returnTo: this.document.location.origin } });
    localStorage.removeItem('access_token');
  };
}
