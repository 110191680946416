import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Import the AuthService type from the SDK
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-auth-button',
//   template: '<p-button label="Log in" (onClick)="auth.loginWithRedirect()"></p-button>'
    template: `
        <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
            <p-button (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })">
                Log out
            </p-button>
        </ng-container>

        <ng-template #loggedOut>
            <!-- <button (click)="auth.loginWithRedirect()">Log in</button> -->
            <p-button label="Log in" (onClick)="auth.loginWithRedirect({authorizationParams: {
           audience: env.AUTH0_AUDIENCE,
           scope: 'openid profile email offline_access'
         }})"></p-button>
        </ng-template>
    `,
})
export class AuthButtonComponent {
  // Inject the authentication service into your component through the constructor
  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService) {}

  env = environment;
}