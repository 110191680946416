import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, concatMap, map, of } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '@ls/common-ts-models';
import { AuthOptions } from '../models';

const ANALYST_ROLE = 'test_transactions:Test_Transactions_Analyst';

export const RoleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAuthenticated$
    .pipe(
      concatMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return of(false);
        } else {
          return authService.getAccessTokenSilently({
            authorizationParams: AuthOptions
          });
        }
      }),
      catchError((err) => {
        if (err.message.includes('invalid refresh token')) {
          authService.loginWithRedirect({
            authorizationParams: AuthOptions
          });
        }
        return of(false);
      })
    )
    .pipe(
      map((token) => {
        if (!token || (token as string)?.length < 1) {
          router.navigate(['/login']);
          return false;
        }

        const decoded: JwtPayload & { permissions: string[] } = jwtDecode(
          token as string,
        );
        const hasRole = decoded.permissions?.includes(ANALYST_ROLE);

        if (!hasRole) {
          router.navigate(['/access-denied']);
          return false;
        }

        return hasRole;
      }),
    );
};
