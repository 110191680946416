<p-dialog 
  header="Batch Upload"
  [(visible)]="showBulkUpload" 
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
  (onHide)="cancelUpload()"
  >
  <div class="bulk-upload-content">
      <div>Upload a CSV to import data to test transactions. A maximum of 200 transactions can be uploaded at a time.</div>
      <div class="overwrite-warning">
        <div>
          <img src="assets/svg/icons/warning.svg" style="height: 24px; width: 24px;" alt="warning" />
        </div>
        <div>
          This will overwrite data for existing transactions that match on website URL and client.
        </div>
      </div>
      <ls-upload
          accept=".csv"
          label="Upload"
          [maxFileSize]="25000000"
          [multiple]="false"
          [singleFile]="true"
          (filesChanged)="onFilesChanged($event)"
      >
      </ls-upload>
      <div class="buttons">
        <p-button label="Cancel" [text]="true" severity="primary" (onClick)="cancelUpload()"></p-button>
        <p-button label="Upload" severity="primary" (onClick)="upload()" [disabled]="!bulkUploadFile || !!(bulkUploadPending$ | async)"></p-button>
      </div>
  </div>
  <div *ngIf="bulkUploadPending$ | async" class="pendingOverlay">
  </div>
</p-dialog>