<div class="transaction-create-component">
    <div class="navbar">
        <p-button
            class="return-button"
            icon="pi pi-home"
            iconPos="left"
            label="Transaction List"
            [rounded]="true"
            [text]="true"
            size="small"
            (onClick)="closeDetails()"
        ></p-button>
        <div class="">
            <i class="pi pi-chevron-right"></i>
        </div>           
        <div *ngIf="!loading" class="breadcrumb">
            Add Test Transaction
        </div>
        <div class="create-buttons">
            <p-button 
                label="Cancel"
                class="cancel-transaction-button"
                [text]="true"
                size="small"
                (onClick)="close()"
            ></p-button>
            <p-button
                class="add-testing-button"
                label="Add Testing"
                icon="pi pi-plus"
                iconPos="left"
                tooltipPosition="bottom" 
                [pTooltip]="formTransactionCreate.invalid ? 'Please fill all required fields' : ''"
                [disabled]="formTransactionCreate.invalid"
                [text]="true"
                size="small"
                (onClick)="onSave()"
            ></p-button>
        </div>
    </div>
   <form [formGroup]="formTransactionCreate">
        <div class="form-container">
            <p-card header="General Information">
                <div class="underline"></div>
                <div class="detail-section">
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Client</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="client" optionLabel="name" [options]="clientOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Client Sub</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="clientSub"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Target Website</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="websiteUrl"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div  class="field-name">Analysis Note</div>
                            <div *ngIf="!loading" class="field-input"><textarea type="text" class="p-inputtext-sm" pInputTextarea [autoResize]="true" formControlName="analysisNote"></textarea></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div  class="field-name">Internal Note</div>
                            <div *ngIf="!loading" class="field-input"><textarea type="text" class="p-inputtext-sm" pInputTextarea [autoResize]="true" formControlName="internalNote"></textarea></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card header="Tracking Information">
                <div class="underline"></div>
                <div class="detail-section">
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">LS Internal UID</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="legitscriptId"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Request Id</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="requestId"></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Record Id</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="id"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div  class="field-name">Record Tags</div>
                            <div *ngIf="!loading" class="field-input">
                                <p-multiSelect
                                    styleClass="{{editDetails ? 'multiselect-edit' : 'readonly-dropdown'}}"
                                    [options]="allTags"
                                    formControlName="recordTags"
                                    [showHeader]="false"
                                    [maxSelectedLabels]="10"
                                    display="chip">
                                    <ng-template let-value pTemplate="selectedItems">
                                        <div class="selected-tag" *ngFor="let tag of value">
                                            <ls-tag [tagInput]="tag"></ls-tag>
                                            <p-button [text]="true" [rounded]="true" size="small" icon="pi pi-times" (onClick)="deselectTag(tag)"></p-button>
                                        </div>
                                    </ng-template>
                                    <ng-template let-tag pTemplate="item">
                                        <div>
                                            <ls-tag [tagInput]="tag"></ls-tag>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                            <p-skeleton width="99%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Source</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="source" [options]="sourceOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Persistent Monitoring</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="monitoringStatus" [options]="persistentMonitoringOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card header="LegitScript Data">
                <div class="underline"></div>
                <div class="detail-section">
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Category</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="category" [filter]="true" [options]="categoryOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Network</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="network"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                </div>
            </p-card>
            <p-card header="Testing Information">
                <div class="underline"></div>
                <div class="detail-section">
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Transaction Status</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="transactionStatus" [options]="transactionStatusOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Analyzed Date</div>
                            <div *ngIf="!loading" class="field-input"><p-calendar dateFormat="yy-mm-dd" formControlName="analysisDate"></p-calendar></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Card Last 4 Digits</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="ccLast4"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Payment Website</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="paymentWebsite"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Purchase Amount</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="purchaseAmount"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Currency</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown formControlName="transactionCurrency" [options]="transactionCurrencyOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">To USD</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionUSDAmount"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Transaction Item</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionDescription"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Cardholder Location</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="cardholderLocation" [options]="cardholderLocationOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Issuer Location</div>
                            <div *ngIf="!loading" class="field-input"><p-dropdown styleClass="{{editDetails ? '' : 'readonly-dropdown'}}" formControlName="issuerLocation" [options]="issuerLocationOptions"></p-dropdown></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Transaction Date</div>
                            <div *ngIf="!loading" class="field-input"><p-calendar dateFormat="yy-mm-dd" formControlName="transactionDate"></p-calendar></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="column-spacer"></div>
                    </div>
                </div>
            </p-card>
            <p-card header="Transaction Details">
                <div class="underline"></div>
                <div class="detail-section">
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Transaction Amount</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionAmount"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Transaction ID / ARN</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="transactionId"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">MCC</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCategoryCode"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">MCC Category</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="mccCategory"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">POS Condition Mode</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="posConditionCode"></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">POS Entry Mode</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="posEntryMode"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Terminal ID</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="terminalId"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="detail-field">
                            <div class="field-name">Card Acceptor ID</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="cardAcceptorId"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                    </div>
                    <div class="detail-row">
                        <div class="detail-field">
                            <div class="field-name">Agent (TPA)</div>
                            <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="agent"/></div>
                            <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                        </div>
                        <div class="column-spacer"></div>
                        <div class="column-spacer"></div>
                        <div class="column-spacer"></div>
                    </div>
                </div>
            </p-card>
                <p-card header="Acquirer Details">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Acquirer Name</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerName"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">BIN</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerBin"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">BID</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerBid"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Country</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerCountry"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Region</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="acquirerRegion"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                            <div class="column-spacer"></div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Merchant Details">
                    <div class="underline"></div>
                    <div class="detial-section">
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">City</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCity"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">State</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantState"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Country</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="merchantCountry"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Merchant Descriptor</div>
                                <div *ngIf="!loading" class="field-input-full-width"><textarea type="text" class="p-inputtext-sm" pInputTextarea formControlName="merchantDescriptor"></textarea></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                    </div>
                </p-card>
                <p-card header="Rights Holder Details">
                    <div class="underline"></div>
                    <div class="detail-section">
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Rights Holder</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolder"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Contact Name</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactName"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Contact Phone</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactPhone"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                            <div class="detail-field">
                                <div class="field-name">Contact Email</div>
                                <div *ngIf="!loading" class="field-input"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactEmail"/></div>
                                <p-skeleton width="95%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                        <div class="detail-row">
                            <div class="detail-field">
                                <div class="field-name">Contact Address</div>
                                <div *ngIf="!loading" class="field-input-full-width"><input type="text" class="p-inputtext-sm" pInputText formControlName="rightsHolderContactAddress"/></div>
                                <p-skeleton width="99%" height="40px" *ngIf="loading"></p-skeleton>
                            </div>
                        </div>
                    </div>
                </p-card>
        </div>
    </form>
</div>