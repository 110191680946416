<div class="login-page">

    <div class="auth-container">
        <app-auth-button></app-auth-button>
    </div>

    <div class="transactions-container" *ngIf="auth.isAuthenticated$ | async">
        <a [routerLink]="'/loading'">Go to Transactions</a>
    </div>
</div>
