import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';
import { AuthOptions } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    public auth: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {

    this.auth
      .getAccessTokenSilently({
        authorizationParams: AuthOptions
      })
      .pipe(takeUntil(this.destroyed$))
      .pipe(catchError((err) => {
        console.error('Error while retrieving token (PAGE)', err);
        this.auth.loginWithRedirect({
          authorizationParams: AuthOptions,
        });

        return throwError(() => new Error(err));
      }))
      .subscribe((token) => {
        localStorage.setItem('access_token', token);
      });

    this.auth.idTokenClaims$.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (x && x.__raw) {
        console.log('Auth completed; redirecting...');
        this.router.navigate(['/transactions']);
      } else {
        console.log('User is not logged in; redirect to login.');
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
