import { TableFilter } from "@ls/common-ng-components";
import { Pagination } from "@ls/common-ts-models";

export interface ListTransactionsParameters{
    page: number,
    size: number,
    sortDirection?: string,
    sortField?: string,
    filters?: TableFilter[],
}

export interface TransactionResponse{
    pagination: Pagination,
    results: Transaction[]
} 

export interface Transaction{
    id: string,
    client: string,
    accountId: string,
    websiteUrl: string,
    category: string,
    source: string,
    requestId: string,
    transactionStatus: string,
    transactionDate: Date,
    analysisDate: Date,
    transactionDescription: string,
    ccLast4: string,
    cardAcceptorId: string,
    transactionAmount: number,
    transactionCurrency: string,
    transactionUSDAmount: number,
    paymentWebsite: string,
    merchantDescriptor: string,
    analysisNote: string,
    internalNote: string,
    monitoringStatus:string,
    createdAt: Date,
    updatedAt: Date,
    createdBy: Date,
    updatedBy: Date,
    deletedAt: Date,
}