import { NgModule } from '@angular/core';
import { RouterModule, Routes, provideRouter, withComponentInputBinding, withHashLocation } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { TransactionsListPageComponent } from './pages/transactions-list-page/transactions-list-page.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { LoginComponent } from './pages/login/login.component';

import { TransactionsDetailPageComponent } from './pages/transaction-detail-page/transaction-detail-page.component';
import { TransactionCreatePageComponent } from './pages/transaction-create-page/transaction-create-page.component';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { RoleGuard } from './guards';


export const routes: Routes = [
  {path: 'loading', component: LoadingPageComponent, canActivate: [AuthGuard, RoleGuard]},
  {
    path: 'transactions',
    component: TransactionsListPageComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  { path: 'transactions/details/:transactionId', component: TransactionsDetailPageComponent, canActivate: [AuthGuard]},
  { path: 'transactions/create', component: TransactionCreatePageComponent, canActivate: [AuthGuard]},
  { path: 'transactions/:transactionId', component: TransactionsDetailPageComponent, canActivate: [AuthGuard, RoleGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'access-denied', component: AccessDeniedPageComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: [
    provideRouter(routes, withHashLocation(), withComponentInputBinding()),
    { provide: 'window', useValue: window },
  ]
})
export class AppRoutingModule { }
