<p-dialog 
  header="Upload Result"
  [(visible)]="showBatchUploadResults" 
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [draggable]="false"
  (onHide)="closeClick()"
  >
    <div class="bulk-upload-result-content">
        <div *ngIf="!errors">Your upload was successful!</div>
        <div *ngIf="errors" class="errors">
            <div class="title">
                <img src="assets/svg/icons/warning.svg" style="height: 24px; width: 24px;" alt="warning" />
                <span>Your upload encountered the following problems:</span>

            </div>
            <div>
                <textarea rows="5" cols="80" pInputTextarea [ngModel]="errors" class="ng-invalid ng-dirty"></textarea>
            </div>
        </div>
        <div *ngIf="ids.length > 0" class="links">
            <div>
                <span>The following transactions were added successfully ({{ ids.length }}):</span>
            </div>
            <div>
                <textarea rows="5" cols="80" pInputTextarea [ngModel]="links.join('\n')"></textarea>
            </div>
        </div>
        <div class="buttons">
            <p-button label="Close" [text]="true" severity="primary" (onClick)="closeClick()"></p-button>
            <p-button label="Copy Links" icon="pi pi-copy" (onClick)="copyIds()"></p-button>
        </div>
    </div>
</p-dialog>