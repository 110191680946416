import { TableFilter } from "@ls/common-ng-components";
import { Pagination } from "@ls/common-ts-models";
import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ListTransactionsParameters, Transaction } from "src/app/models";
import { CategoryObject, 
         GetTransaction200Response, 
         WebsiteObject,
         UpdateTransactionRequestParams,
         CreateTransaction201Response,
         UpdateAWebsiteRequestParams,
         TagObject,
         CreateTransactionRequest, 
         AnalystSearchForTransactionsRequestParams,
         UploadFileRequest,
         DownloadFileResponse} from "src/app/test-transactions-client";
// TODO: Change the unknown/any/never types

export const TransactionsActions = createActionGroup({
    source: 'Transactions',
    events: {
        'Bulk Upload Request': props<{file: File}>(),
        'Bulk Upload Success': props<{ ids: string[] }>(),
        'Bulk Upload Error': props<{error: string, ids: string[]}>(),
        'Create Transaction': props<{request: CreateTransactionRequest }>(),
        'Create Transaction Success': props<{response: CreateTransaction201Response }>(),
        'Create Transaction Error': props<{error: string}>(),
        'Delete Transaction': props<{transactionId: string}>(),
        'Delete Transaction Success': emptyProps,
        'Delete Transaction Error': props<{error: string}>(),
        'Download Table Transactions': props<{sortField: string, sortDirection: string, filters: TableFilter[]}>(),
        'Download Table Transactions Success': props<{ data: Blob}>(),
        'Download Table Transactions Error': props<{ error: string}>(),
        'Get Screenshot Upload Url': props<{ file: File, transactionId: string, fileName: string, relativePosition: number }>(),
        'Get Screenshot Upload Url Success': props<{url: string, file: File, fileName: string, fileId: string, fields: any, relativePosition: number }>(),
        'Confirm Screenshot Upload': props<{fileId: string, fileName: string, uploadUrl: string, relativePosition: number }>(),
        'Confirm Screenshot Upload Success': props<{fileId: string, fileName: string, uploadUrl: string, relativePosition: number }>(),
        'Reorder Screenshots': props<{transactionId: string, orderedScreenshots: any[]}>(),
        'Reorder Screenshots Success': emptyProps(),
        'Download All Screenshots': props<{transactionId: string}>(),
        'Download All Screenshots Success': props<{screenshots: any[]}>(),
        'Download Screenshot': props<{transactionId: string, screenshotId: string}>(),
        'Download Screenshot Success': props<{response: DownloadFileResponse}>(),
        'Delete Screenshot': props<{transactionId: string, screenshotId: string}>(),
        'Delete Screenshot Success': emptyProps(),
        'Get Attachment Upload Url': props<{file: File, transactionId: string, fileName: string  }>(),
        'Get Attachment Upload Url Success': props<{url: string, file: File, fileName: string, fileId: string, fields: any }>(),
        'Confirm Attachment Upload': props<{fileId: string, fileName: string, uploadUrl: string}>(),
        'Confirm Attachment Upload Success': props<{fileId: string, fileName: string, uploadUrl: string}>(),
        'Delete Attachment': props<{transactionId: string, attachmentId: string}>(),
        'Delete Attachment Success': emptyProps(),
        'Download All Attachments': props<{transactionId: string}>(),
        'Download All Attachments Success': props<{attachments: any[]}>(),
        'Upload File Error': props<{error: string}>(),
        'List': props<{ searchParams: ListTransactionsParameters}>(),
        'List Success': props<{transactions: Transaction[], pagination: Pagination }>(),
        'List Error': props<{error: string}>(),
        'Get Unique Column Values': props<{ column: string }>(),
        'Get Unique Column Values Success': props<{
            column: string;
            values: string[];
            tags?: TagObject[];
            categories?: CategoryObject[];
          }>(),
        'Get Unique Column Values Error': props<{ errorText: string; errorType: number }>(),
        'Get Transaction': props<{id: string}>(),
        'Get Transaction Success': props<{transaction: GetTransaction200Response}>(),
        'Get Transaction Website Success': props<{website: WebsiteObject}>(),
        'Get Transaction Error': props<{error: string}>(),
        'Get All Categories': emptyProps(),
        'Get All Categories Success': props<{categories: CategoryObject[]}>(),
        'Get All Categories Error': props<{error: string}>(),
        'Update Transaction': props<{request: UpdateTransactionRequestParams}>(),
        'Update Transaction Success': props<{transaction: CreateTransaction201Response}>(),
        'Update Transaction Error': props<{error: string}>(),
        'Update Website': props<{request: UpdateAWebsiteRequestParams}>(),
        'Update Website Success': props<{website: WebsiteObject}>(),
        'Update Website Error': props<{error: string}>(),
        'Get All Accounts': emptyProps(),
        'Get All Accounts Success': props<{accounts: any[]}>(),
        Noop: emptyProps(),
    }
})
