import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TransactionState, TransactionsActions, TransactionsUniqueValuesState } from 'src/app/store';
import { selectBulkUploadStatus, selectTransactionsNode, selectUniqueValuesState } from 'src/app/store/transactions/transactions.selectors';
import { ColumnConfig, columnsToGet, transactionListColumnConfig } from './transactions-list-config';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterCategory, FilterType, TableFilter, isFilterEmpty } from '@ls/common-ng-components';
import { ListTransactionsParameters } from 'src/app/models';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transactions-list-page',
  templateUrl: './transactions-list-page.component.html',
  styleUrls: ['./transactions-list-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsListPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  public columnConfig: ColumnConfig[] =  transactionListColumnConfig;

  public pageSize = 50;

  public visibleFilters: TableFilter[] = [];
  public appliedFilters: TableFilter[] = [];
  public showBatchUpload = false;
  public showBatchUploadResults = false;
  public transactionsState$: Observable<TransactionState> = this.store.select(selectTransactionsNode);

  private currentSearchParams: ListTransactionsParameters = {
    page: 1,
    size: this.pageSize,
    filters: []
  }
  private transactionUniqueValues$: Observable<TransactionsUniqueValuesState> = this.store.select(selectUniqueValuesState);

  private allFilters: {
    [key: string]: TableFilter;
  } = {
    recordId: {
      displayName: 'Record ID',
      filterType: FilterType.multi_line,
      propertyName: 'id',
      exactMatch: true,
    },
    client: {
      displayName: 'Client',
      filterType: FilterType.multi_select,
      propertyName: 'client',
      multiSelectOptions: [],
    },
    website: {
      displayName: 'Target Website',
      filterType: FilterType.multi_line,
      propertyName: 'websiteUrl',
      exactMatch: true,
    },
    category: {
      displayName: 'Category',
      filterType: FilterType.multi_select,
      propertyName: 'category',
      multiSelectOptions: [],
      showOptionHeader: true,
    },
    sources: {
      displayName: 'Transaction Source',
      filterType: FilterType.multi_select,
      propertyName: 'source',
      multiSelectOptions: [],
    },
    requestId: {
      displayName: 'Request ID',
      filterType: FilterType.single_line,
      propertyName: 'requestId',
    },
    transactionStatus: {
      displayName: 'Transaction Status',
      filterType: FilterType.multi_select,
      propertyName: 'status',
      multiSelectOptions: [],
    },
    transactionDate: {
      displayName: 'Transaction Date',
      filterType: FilterType.date_range,
      propertyName: 'transactionDate',
      startDate: new Date(),
      endDate: new Date(),
    },
    analyzedDate: {
      displayName: 'Analyzed Date',
      filterType: FilterType.date_range,
      propertyName: 'analysisDate',
      startDate: new Date(),
      endDate: new Date(),
    },
    transactionDescription: {
      displayName: 'Transaction Item',
      propertyName: 'transactionDescription',
      filterType: FilterType.single_line,
    },
    cc: {
      displayName: 'Card Last 4 Digits',
      filterType: FilterType.single_line,
      propertyName: 'ccLast4',
    },
    transactionAmount: {
      displayName: 'Transaction Amount (local)',
      filterType: FilterType.numeric_range,
      propertyName: 'transactionAmount',
      numericRange: [0, 1],
    },
    currency: {
      displayName: 'Currency',
      filterType: FilterType.multi_select,
      propertyName: 'transactionCurrency',
      multiSelectOptions:  [],
    },
    usd: {
      displayName: 'Transaction Amount (USD)',
      filterType: FilterType.numeric_range,
      propertyName: 'transactionUSDAmount',
      numericRange: [0,1],
    },
    paymentWebsite: {
      displayName: 'Payment Website',
      filterType: FilterType.single_line,
      propertyName: 'paymentWebsite',
    },
    merchant: {
      displayName: 'Merchant Descriptor',
      filterType: FilterType.single_line,
      propertyName: 'merchantDescriptor',
    },
    persistentMonitoring: {
      displayName: 'Persistent Monitoring',
      filterType: FilterType.multi_select,
      propertyName: 'monitoringStatus',
      multiSelectOptions: [],
    },
  };

  public availableFilters: FilterCategory[] = [];

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    ) {}

  ngOnInit(): void {
    this.setupFilters();
    this.store.dispatch(TransactionsActions.getAllAccounts());
    this.store.dispatch(TransactionsActions.list({searchParams:{...this.currentSearchParams}}));
    this.store.dispatch(TransactionsActions.getAllCategories());

    this.store.select(selectBulkUploadStatus).subscribe(status => {
      if (!status) {
        this.showBatchUploadResults = false;
      } else {
        this.showBatchUploadResults = true;
      }
    });
  }
  onClickDetailCell(transactionId: string){
    const newRelativeUrl = this.router.createUrlTree(['transactions',transactionId]);
    const baseUrl = window.location.href.replace(this.router.url, '');

    window.open(baseUrl + newRelativeUrl, '_blank');
  }
  onSortChange($event: any){
    const direction = $event.sortOrder === 1 ? 'asc' : 'desc';
    this.store.dispatch(TransactionsActions.list({
      searchParams:{
        ...this.currentSearchParams,
        sortField: $event.sortField,
        sortDirection: direction,
      }
    }));
  }
  onPageChange($event: any){
    this.currentSearchParams.page = $event.page+1;
    this.store.dispatch(TransactionsActions.list({searchParams: {...this.currentSearchParams}}));
  }

  onAddFilter($event: TableFilter){
    this.visibleFilters = [...this.visibleFilters, {...$event}];
  }

  onApplyFilter($event: TableFilter) {
    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }
    this.appliedFilters = [...this.appliedFilters.filter((f) => f.propertyName !== $event.propertyName), {...$event}];
    this.visibleFilters = this.visibleFilters.map((vf) => vf.displayName === $event.displayName ? {...$event} : vf);
    this.currentSearchParams = {
      ...this.currentSearchParams,
      filters: [
        ...this.currentSearchParams.filters!,
        $event
      ]
    }
    this.store.dispatch(TransactionsActions.list({
      searchParams: {
        ...this.currentSearchParams,
        filters: this.appliedFilters
      }
    }));
  }

  onRemoveFilter($event: TableFilter){
    if(!isFilterEmpty($event)){
      this.visibleFilters = (this.visibleFilters ?? []).map((vf) => {
        if(vf.displayName === $event.displayName){
          delete vf.value;
          return {
            ...vf,
            someNewField: "hello"
          }
        }
        return {...vf}
      });
      this.appliedFilters = (this.appliedFilters ?? []).filter((af) => af.displayName !== $event.displayName);
      this.currentSearchParams = {
        ...this.currentSearchParams,
        filters: this.appliedFilters
      }
      this.store.dispatch(TransactionsActions.list({searchParams: this.currentSearchParams}));
    }
    else{
      this.visibleFilters = (this.visibleFilters ?? []).filter((vf) => vf.displayName !== $event.displayName);
    }
  }

  onClearFilters(){
    this.visibleFilters = this.visibleFilters.map((vf) => ({...vf, value: undefined}));
    this.store.dispatch(TransactionsActions.list({
      searchParams:{
        ...this.currentSearchParams,
        filters: []
      }
    }));
  }
  exportTransactions() {
    let isRequested = false;
    this.transactionsState$
      .pipe(
        map((state: TransactionState) => state.downloadTransactionsState),
        filter((downloadState) => !downloadState.downloadPending && !!downloadState.transactionsFile && isRequested),
        take(1),
      )
      .subscribe((downloadState) => {
        this.saveBlob(downloadState.transactionsFile as Blob, `Test Transactions [${new Date().toISOString()}].csv`);
      });
    this.store.dispatch(
      TransactionsActions.downloadTableTransactions({
        sortField: this.currentSearchParams.sortField as string,
        sortDirection: this.currentSearchParams.sortDirection as string,
        filters: this.currentSearchParams.filters as TableFilter[],
      }),
    );
    isRequested = true;
  }

  saveBlob(blob: Blob, name: string) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    document.body.removeChild(link);
  }

  onAddTransactionClick = () => {
    this.router.navigate(['transactions/create']);
  }

  onBatchUploadClick = () => {
    this.showBatchUpload = true;
  }

  onBatchUploadResultsClick = (ids?: string[]) => {

    this.showBatchUploadResults = false;

    if (ids) {
      const filter: TableFilter = {displayName: 'Record ID', filterType: FilterType.multi_line, propertyName: 'id', value: ids.join(','), };
      const searchParams: ListTransactionsParameters = {
        ...this.currentSearchParams,
        filters: [
          filter
        ]
      };
      this.store.dispatch(TransactionsActions.list({ searchParams }));

      this.visibleFilters = [{...filter, value: ids.join('\n')}];
      this.appliedFilters = [{...filter, value: ids.join('\n')}];
    }
  }

  onCopyLinkClick = (link: string) => {
    navigator.clipboard.writeText(link);
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Link copied to clipboard' });
  }

  ensureHttpPrefix = (link: string): string => {
    if (link.startsWith('http')) {
      return link;
    }
    return `http://${link}`;
  }

  setupFilters(){// filter selectors
    columnsToGet.forEach((fc) => this.store.dispatch(TransactionsActions.getUniqueColumnValues({column: fc})));

    this.transactionUniqueValues$.subscribe((state) =>{
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const transactionAmountToNumbers = state.columns.transactionAmount.map((a) => +a);
      const transactionUsdAmountToNumbers = state.columns.transactionAmount.map((a) => +a);
      const transactionDates = state.columns.transactionDate.map((d) => {
        const date = new Date(d);
        date.setHours(0, 0, 0, 0);
        return date;
      });
      const analysisDates = state.columns.analysisDate.map((d) => {
        const date = new Date(d);
        date.setHours(0, 0, 0, 0);
        return date;
      });

      this.allFilters = {
        legitscriptId: {
          displayName: 'LS Internal UID',
          filterType: FilterType.single_line,
          propertyName: 'legitscriptId'
        },
        recordId: {
          displayName: 'Record ID',
          filterType: FilterType.multi_line,
          propertyName: 'id',
          exactMatch: true,
        },
        client: {
          displayName: 'Client',
          filterType: FilterType.multi_select,
          propertyName: 'client',
          multiSelectOptions: state.columns.clients.map((c) => {return { name: c }}),
        },
        clientSub: {
          displayName: 'Client Sub',
          filterType: FilterType.single_line,
          propertyName: 'clientSub',
        },
        website: {
          displayName: 'Target Website',
          filterType: FilterType.multi_line,
          propertyName: 'websiteUrl',
          exactMatch: true,
        },
        category: {
          displayName: 'Category',
          filterType: FilterType.multi_select,
          propertyName: 'category',
          multiSelectOptions: state.columns.category.map((c) => {return { name: c }}),
          showOptionHeader: true,
          filterOptionsBy: 'name',
        },
        sources: {
          displayName: 'Source',
          filterType: FilterType.multi_select,
          propertyName: 'source',
          multiSelectOptions: state.columns.source.map((c) => {return { name: c }}),
        },
        requestId: {
          displayName: 'Request ID',
          filterType: FilterType.single_line,
          propertyName: 'requestId',
        },
        transactionStatus: {
          displayName: 'Transaction Status',
          filterType: FilterType.multi_select,
          propertyName: 'transactionStatus',
          multiSelectOptions: state.columns.transactionStatus.map((c) => {return { name: c }}),
        },
        transactionDate: {
          displayName: 'Transaction Date',
          filterType: FilterType.date_range,
          propertyName: 'transactionDate',
          startDate: transactionDates[0],
          endDate: today,
        },
        analyzedDate: {
          displayName: 'Analyzed Date',
          filterType: FilterType.date_range,
          propertyName: 'analysisDate',
          startDate: analysisDates[0],
          endDate: today,
        },
        transactionDescription: {
          displayName: 'Transaction Item',
          propertyName: 'transactionDescription',
          filterType: FilterType.single_line,
        },
        cc: {
          displayName: 'Card Last 4 Digits',
          filterType: FilterType.single_line,
          propertyName: 'ccLast4',
        },
        transactionAmount: {
          displayName: 'Transaction Amount (local)',
          filterType: FilterType.numeric_range,
          propertyName: 'transactionAmount',
          numericRange: [Math.min(...transactionAmountToNumbers), Math.max(...transactionAmountToNumbers)],
        },
        currency: {
          displayName: 'Currency',
          filterType: FilterType.multi_select,
          propertyName: 'transactionCurrency',
          multiSelectOptions:  state.columns.transactionCurrency.map((c) => {return { name: c }}),
        },
        usd: {
          displayName: 'Transaction Amount (USD)',
          filterType: FilterType.numeric_range,
          propertyName: 'transactionUSDAmount',
          numericRange: [Math.min(...transactionUsdAmountToNumbers), Math.max(...transactionUsdAmountToNumbers)],
        },
        paymentWebsite: {
          displayName: 'Payment Website',
          filterType: FilterType.single_line,
          propertyName: 'paymentWebsite',
        },
        mccCategory: {
          displayName: 'MCC Category',
          filterType: FilterType.single_line,
          propertyName: 'mccCategory',
        },
        merchant: {
          displayName: 'Merchant Descriptor',
          filterType: FilterType.single_line,
          propertyName: 'merchantDescriptor',
        },
        merchantState: {
          displayName: 'Merchant State',
          filterType: FilterType.multi_line,
          propertyName: 'merchantState',
          exactMatch: true,
        },
        merchantCountry: {
          displayName: 'Merchant Country',
          filterType: FilterType.multi_select,
          propertyName: 'merchantCountry',
          multiSelectOptions: state.columns.merchantCountry.map((mc) => {return {name: mc}}),
        },
        persistentMonitoring: {
          displayName: 'Persistent Monitoring',
          filterType: FilterType.multi_select,
          propertyName: 'monitoringStatus',
          multiSelectOptions: state.columns.monitoringStatus.map((c) => {return { name: c }}),
        },
        transactionId: {
          displayName: 'Transaction ID / ARN',
          filterType: FilterType.single_line,
          propertyName: 'transactionId',
        },
        terminalId: {
          displayName: 'Terminal ID',
          filterType: FilterType.single_line,
          propertyName: 'terminalId',
        },
        recordTags: {
          displayName: 'Record Tags',
          filterType: FilterType.multi_select,
          propertyName: 'recordTags',
          multiSelectOptions: state.columns.recordTags.map((c) => {return { name: c }}),
        },
        cardAcceptor: {
          displayName: 'Card Acceptor ID',
          filterType: FilterType.single_line,
          propertyName: 'cardAcceptor',
        },
        rightsHolder: {
          displayName: 'Rights Holder',
          filterType: FilterType.single_line,
          propertyName: 'rightsHolder',
        },
        acquirerName: {
          displayName: 'Acquirer Name',
          propertyName: 'acquirerName',
          filterType: FilterType.single_line,
        },
        acquirerBin: {
          displayName: 'Acquirer BIN',
          propertyName: 'acquirerBin',
          filterType: FilterType.single_line,
        },
        acquirerBid: {
          displayName: 'Acquirer BID',
          propertyName: 'acquirerBid',
          filterType: FilterType.single_line,
        },
        acquirerCountry: {
          displayName: 'Acquirer Country',
          propertyName: 'acquirerCountry',
          filterType: FilterType.multi_select,
          multiSelectOptions: state.columns.acquirerCountry.map((ac) => {return {name: ac}}),
        },
        acquirerRegion: {
          displayName: 'Acquirer Region',
          propertyName: 'acquirerRegion',
          filterType: FilterType.single_line,
        },
        merchantCategoryCode: {
          displayName: 'MCC',
          propertyName: 'merchantCategoryCode',
          filterType: FilterType.single_line,
        },
        agent: {
          displayName: 'Agent (TPA)',
          propertyName: 'agent',
          filterType: FilterType.single_line,
        },
        issuerLocation: {
          displayName: 'Issuer Location',
          filterType: FilterType.multi_select,
          propertyName: 'issuerLocation',
          multiSelectOptions: state.columns.acquirerCountry.map((c) => { return { name: c } }),
        },
        cardholderLocation: {
          displayName: 'Cardholder Location',
          filterType: FilterType.multi_select,
          propertyName: 'cardholderLocation',
          multiSelectOptions: state.columns.acquirerCountry.map((c) => { return { name: c } }),
        },
      };
      this.availableFilters = [
        {
          name: 'By request details',
          memberFilters: [
            this.allFilters['legitscriptId'],
            this.allFilters['requestId'],
            this.allFilters['recordId'],
            this.allFilters['terminalId'],
            this.allFilters['recordTags'],
          ],
        },
        {
          name: 'By date',
          memberFilters: [this.allFilters['transactionDate'], this.allFilters['analyzedDate']],
        },
        {
          name: 'By transaction details',
          memberFilters: [
            this.allFilters['transactionId'],
            this.allFilters['client'],
            this.allFilters['clientSub'],
            this.allFilters['sources'],
            this.allFilters['transactionStatus'],
            this.allFilters['cc'],
            this.allFilters['cardAcceptor'],
            this.allFilters['transactionAmount'],
            this.allFilters['usd'],
            this.allFilters['issuerLocation'],
            this.allFilters['cardholderLocation'],
          ],
        },
        {
          name: 'URL details',
          memberFilters: [
            this.allFilters['website'],
            this.allFilters['paymentWebsite'],
            this.allFilters['category'],
            this.allFilters['persistentMonitoring'],
            this.allFilters['rightsHolder'],
            this.allFilters['acquirerName'],
            this.allFilters['acquirerBin'],
            this.allFilters['acquirerBid'],
            this.allFilters['acquirerCountry'],
            this.allFilters['acquirerRegion'],
            this.allFilters['merchantCategoryCode'],
          ],
        },
        {
          name: 'Merchant details',
          memberFilters: [
            this.allFilters['merchant'],
            this.allFilters['merchantState'],
            this.allFilters['merchantCountry'],
            this.allFilters['mccCategory'],
          ],
        },
      ];
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
